import { useState } from 'react';

import Config from 'utils/config'

import {
	Box,
	Button,
	IconButton,
	Paper,
	Table,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
	Tooltip,
	Typography,
	AlertTitle,
	Alert
} from '@mui/material';

import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Edit as EditIcon
} from '@mui/icons-material';

import BeliefDialog from './belief-dialog'
import DeleteDialog from 'components/delete-dialog';

import {
	BeliefProps
} from 'utils/types';


export interface DiseaseAreaBeliefsProps {
	beliefs: BeliefProps[],
	setBeliefs: (beliefs: BeliefProps[]) => void,
	validateForm: (overrideData: any | undefined) => boolean,
	errors: any
}

const DiseaseAreaBeliefs = ({ beliefs, setBeliefs, validateForm, errors } : DiseaseAreaBeliefsProps) => {
	
	const [deleteBeliefDialog, setDeleteBeliefDialog] = useState<any | null>(null)

	// dialog states.
	const [openBeliefDialog, setOpenBeliefDialog] = useState(false);
	const [beliefValue, setBeliefValue] = useState('');
	const [editBeliefIndex, setEditBeliefIndex] = useState<number>(-1)

	const handleCloseBeliefDialog = () => {
		setOpenBeliefDialog(false)
		setBeliefValue('')
		setEditBeliefIndex(-1)
	}

	// clicked to edit a belief.
	const editBelief = (value: string, index: number) => {
		setBeliefValue(value);
		setEditBeliefIndex(index);
		setOpenBeliefDialog(true);
	}

	const handleSubmitBeliefDialog = (value: string | number, index: number | null) => {

		const temp = [...beliefs]

		if(index !== null && index > -1) 
		{
			temp[index].value = value;
		} 
		else 
		{
			temp.push({
				id: 0, 
				value: value 
			})
		}

		setBeliefs(temp);
		validateForm({ beliefs: temp })

		setBeliefValue('');
		setEditBeliefIndex(-1);
		setOpenBeliefDialog(false);
	}

	const deleteBelief = (value: any) => {
		setDeleteBeliefDialog(value);
	}

	const onDeleteSubmit = (value : any) => {
		setDeleteBeliefDialog(null);

		const temp = [...beliefs];
		temp.splice(value, 1);
		setBeliefs(temp);
		validateForm({ beliefs: temp })

		setDeleteBeliefDialog(null);
	}

	const onCloseDeleteDialog = () => {
		setDeleteBeliefDialog(null);
	}
	
	return (
		<>
			<Box sx={{ mb: 2 }}>
				<Typography variant="h5">
					<strong>Add MCPs</strong>
				</Typography>
			</Box>
			<Box sx={{ mb: 2 }}>
				{
					errors['beliefs'] && 
					<Alert severity="error" sx={{mb:2}}>
						<AlertTitle>Error</AlertTitle>
						{errors['beliefs'].message}
					</Alert>
				}

				{beliefs.length < Config.MAX_BELIEFS
				 	? <Button
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => setOpenBeliefDialog(true)}
						sx={{ mr: 2 }}
					>Add MCP</Button>
					: <Button variant="contained" disabled>Add MCP</Button>
				}
			</Box>

			<TableContainer component={Paper} sx={{ mb: 4 }}>
				<Table sx={{ minWidth: 650 }} aria-label="MCPs">
					<caption>you have <strong>{Config.MAX_BELIEFS - beliefs.length}/{Config.MAX_BELIEFS}</strong> MCPs remaining.</caption>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '0%' }}>#</TableCell>
							<TableCell>MCP</TableCell>
							<TableCell sx={{ width: '0%' }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{beliefs.map((label, index) => (
							<TableRow key={index}>
								<TableCell>
									{index + 1}
								</TableCell>
								<TableCell>
									{label.value}
								</TableCell>
								<TableCell padding="checkbox" sx={{ whiteSpace: 'nowrap', pr: 1 }} >
									<Tooltip arrow={true} title="Edit MCP">
										<IconButton aria-label="edit" onClick={() => editBelief(label.value as string, index)} size="small">
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip arrow={true} title="Delete MCP">
										<IconButton aria-label="delete" onClick={() => deleteBelief(index)}  size="small">
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<BeliefDialog
				open={openBeliefDialog}
				editId={editBeliefIndex}
				defaultValue={beliefValue}
				onClose={handleCloseBeliefDialog}
				onSubmit={handleSubmitBeliefDialog}
			/>		

			<DeleteDialog
				open={deleteBeliefDialog !== null}
				id={deleteBeliefDialog}
				onClose={onCloseDeleteDialog}
				onSubmit={onDeleteSubmit}
			>
				<Typography sx={{mb:2}}>Your MCP and any related data will be permanently deleted.</Typography>
			</DeleteDialog>
		</>
	);
}

export default DiseaseAreaBeliefs;