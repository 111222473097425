import { Route, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import DefaultLayout from 'layout/default-layout';

import Dashboard from 'pages/dashboard/dashboard'
import DiseaseArea from 'pages/admin/disease-areas/disease-area'
import AdminDashboard from 'pages/admin/admin'

import './App.css';
import Config from 'utils/config'

const oktaAuth = new OktaAuth({
  issuer: Config.oidc.issuer,
  clientId: Config.oidc.clientId,
  redirectUri: window.location.origin + Config.oidc.redirectUri,
  scopes: Config.oidc.scopes
});

const App = () => {

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <DefaultLayout>
        <SecureRoute exact={true} path="/" component={Dashboard} />
        <SecureRoute path="/dashboard/:guid"  component={Dashboard} />
        <SecureRoute exact={true} path="/admin" component={AdminDashboard} />
        <SecureRoute exact={true} path="/admin/disease-area" component={DiseaseArea} />
        <SecureRoute path="/admin/disease-area/:guid" component={DiseaseArea} />
        <Route path="/login/callback" component={LoginCallback}/>
      </DefaultLayout>
    </Security>
  )
}

export default App;
