import { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';

import DeleteDialog from 'components/delete-dialog';
import { selectAdmin } from 'store/slices/admin';
import BusinessUnitsClient from 'api/business-unit-client';
import { fetchBusinessUnits } from 'store/slices/business-unit';

interface DeleteBusinessUnitDialogProps {
  id: string | null
  onClose: () => void
  onDeleted: () => void
}

const DeleteBusinessUnitDialog = ({ id, onDeleted, onClose }: DeleteBusinessUnitDialogProps) => {

  const [businessUnitsClient] = useState(new BusinessUnitsClient())

  const dispatch = useAppDispatch();
  const adminStore = useAppSelector(selectAdmin)

  const onCloseDeleteBusinessUnitDialog = () => {
    onClose()
  }

  const onDeleteBusinessUnitSubmit = (id: string) => {
    businessUnitsClient.deleteAsync(adminStore.accessToken, id).then(() => {
      onClose()
      onDeleted()
      dispatch(fetchBusinessUnits())
    })
  }

  return (
    <DeleteDialog
      open={id !== null}
      id={id}
      onClose={onCloseDeleteBusinessUnitDialog}
      onSubmit={onDeleteBusinessUnitSubmit}
    >
      <span>Are you sure you want to delete? You will not be able to recover this data.</span>
    </DeleteDialog>
  )
}

export default DeleteBusinessUnitDialog;