import { DiseaseAreaSupportTablesProps } from "../store/slices/dashboard";
import { fetchWithAuth } from "./request";

export default class DiseaseAreaClient {

    public async getDiseaseAreaAsync (accessToken: string, id: string) {
		return await fetchWithAuth(accessToken, `/api/diseaseareas/${encodeURIComponent(id)}`, {
			method: 'GET'
		})
		.then(result => {
			if(!result.ok) throw new Error(result.statusText)
			return result.json();
		})
	}

    public async getFilteredDiseaseAreasAsync(accessToken: string, businessUnitId: string): Promise<any> {
        return await fetchWithAuth(accessToken, `api/diseaseareas/filtered?businessUnitId=${encodeURIComponent(businessUnitId)}`,{
            method: "GET",
        })
        .then(result => {
            if(!result.ok) throw new Error(result.statusText)
            return result.json()
        })
        .catch((error) => {
            console.error("Error retrieving disssese areas")
        });
    }

    public async getDiseaseAreaSupportTables(accessToken: string, filters : DiseaseAreaSupportTablesProps): Promise<any> {
        return await fetchWithAuth(accessToken, `/api/diseaseareas/filtered-as-support-table?id=${filters.businessUnitId}&year=${filters.year}`, {})
            .then((result => {
            return result.json()
        }))
        .catch(ex => {
            console.error(ex);
        })
    }

    public async postDiseaseAreaAsync (accessToken: string, data: any): Promise<any> {
		return await fetchWithAuth(accessToken, '/api/diseaseareas/addorupdate', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
		.then(result => {
			if(!result.ok) throw new Error(result.statusText)
			return result;
		})
	}

    public async deleteDiseaseAreaAsync (accessToken: string, id: string): Promise<any> {
		return await fetchWithAuth(accessToken, `/api/diseaseareas/${encodeURIComponent(id)}`, {
            method: 'DELETE'
        })
	}
}