import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {
	Box,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { QuestionProps } from '../utils/types';
import Constants from '../utils/constants'

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
	responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const
    }
  },
}

const label_max_chars_lg = 90;
const label_max_chars_sm = 60;
const label_max_chars_xs = 40;

export interface PieChartProps {
  question: QuestionProps
}

const PieChart : React.FunctionComponent<PieChartProps> = ({ question }: PieChartProps) => {

  const [ labels, setLabels ] = useState<string[]>([])
  const [ data, setData ] = useState<number[]>([])
  const [ ready, setReady ] = useState(false)

  const updateChartData = useCallback((windowWidth: number) => {
    let options = question.options.filter(x => x.value !== Constants.NotAvailableOptionValue);

    let maxChars = windowWidth >= 600 
      ? label_max_chars_lg 
      : windowWidth >= 500
        ? label_max_chars_sm
        : label_max_chars_xs

    setLabels(options.map(x => x.value.length > maxChars ? x.value.slice(0, maxChars) + "..." : x.value))
    setData(options.map(x => x.data ?? 0))
    setReady(true)
  }, [ question ])

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Votes',
        data: data,
        backgroundColor: [
          'rgba(33, 150, 243, 0.75)', // blue
          'rgba(255, 36, 42, 0.75)',  // red
          'rgba(156, 39, 176, 0.75)', // purple
          'rgba(76, 175, 80, 0.75)',  // green
          'rgba(255, 152, 0, 0.75)',  // yellow
          'rgba(233, 30, 99, 0.75)',  // pink
        ],
        borderColor: [
          'rgba(33, 150, 243, 1)',
          'rgba(255, 36, 42, 1)',
          'rgba(156, 39, 176, 1)',
          'rgba(76, 175, 80, 1)',
          'rgba(255, 152, 0, 1)',
          'rgba(233, 30, 99, 1)'
        ],
        borderBackgroundColor: [
          'rgba(33, 150, 243, 1)',
          'rgba(255, 36, 42, 1)',
          'rgba(156, 39, 176, 1)',
          'rgba(76, 175, 80, 1)',
          'rgba(255, 152, 0, 1)',
          'rgba(233, 30, 99, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    
  }, [updateChartData])

  useLayoutEffect(() => {
    const onResize = () => {
      updateChartData(window.innerWidth);
    };
    window.addEventListener('resize', onResize);

    updateChartData(window.innerWidth);

    return () => window.removeEventListener('resize', onResize);
  }, [updateChartData]);

  if(!ready) {
    return <></>
  }

	return (
		<Box sx={{display:'flex', width:'100%'}}>
      <Card sx={{width:'100%'}}>
        <CardHeader title={question.question}></CardHeader>
        <CardContent>
          <Box sx={{maxHeight:'270px', height: '100%'}}>
            <Pie data={chartData} options={options} height={270} />
          </Box>
        </CardContent>
      </Card>
		</Box>
	)
}

export default PieChart;