import * as React from 'react';

import {
	Box,
	CircularProgress
} from '@mui/material';

import {
	
} from '@mui/icons-material';

const Loader : React.FunctionComponent = (props) => {
	return (
	<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow:1 }}>
      <CircularProgress color="secondary" />
    </Box>
	)
}

export default Loader;