import { BusinessUnitProps } from "../utils/types";
import { fetchWithAuth } from "./request";

export default class BusinessUnitsClient {

    public async getBusinessUnitsAsync(accessToken: string): Promise<BusinessUnitProps[]> {
        return await fetchWithAuth(accessToken, '/api/businessunits', {
            method: 'GET'
        })
        .then(result => result.json())
        .then(data => {
            return data;
        });
    }

    public async getBusinessUnitAsync(accessToken: string, id : string): Promise<BusinessUnitProps> {
		return fetchWithAuth(accessToken, `/api/businessunits/${encodeURIComponent(id)}`, {
			method: 'GET',
		})
		.then(result => {
			if(!result.ok) throw new Error(result.statusText)
			else return result.json()
		})
		.catch((error) => {
			console.error("error: " + error);
		});
	}

	public async postAsync(accessToken: string, businessUnit: any): Promise<BusinessUnitProps[]> {
        return await fetchWithAuth(accessToken, '/api/businessunits', {
            method: 'POST',
			headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(businessUnit)
        })
        .then(result => {
            if(result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText)
            }
        })
    }

	public async putAsync(accessToken: string, businessUnit: any): Promise<BusinessUnitProps[]> {
        return await fetchWithAuth(accessToken, '/api/businessunits', {
            method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(businessUnit)
        })
        .then(result => {
            if(result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText)
            }
        })
    }

	public async deleteAsync(accessToken: string, id: string): Promise<any> {
        return await fetchWithAuth(accessToken, '/api/businessunits/' + id, {
            method: 'DELETE'
        })
        .then(result => {
            if(result.ok) {
                return result
            } else {
                throw new Error(result.statusText)
            }
        })
    }
}