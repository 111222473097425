import * as React from 'react';
import { useAppSelector, useAppDispatch } from "app/hooks";

import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Select,
	FormHelperText,
	Stepper,
	Step,
	StepLabel,
	InputLabel,
	FormControl,
	MenuItem,
	Typography,
	IconButton,
	Alert,
	TextField,
} from '@mui/material';

import {
	Close as CloseIcon
} from '@mui/icons-material';

import {
	getInsightSupportTables,
	getPhysicianInsightData,
	selectInsight,
	submitInsight,
	submitNewPhysician,
} from 'store/slices/insight';

import NewPhysician, { PhysicianFormProps } from './new-physician';
import { useValidator, ValidatorMapProps } from './validator';
import { DiseaseAreaProps, SupportTableProps } from 'utils/types';
import { useState, useEffect } from 'react';
import Constants from "utils/constants"

export interface DialogTitleProps {
	children?: React.ReactNode;
	onClose: () => void;
}

export interface InsightsDialogProps {
	diseaseArea: DiseaseAreaProps;
	years: SupportTableProps[];
	quarters: SupportTableProps[];
	currentQuarter: number;
	open: boolean;
	onSubmit: (data: any) => void;
	onPhysicianCreated: () => void;
	onClose: () => void;
}

export interface InsightFormProps {
	diseaseAreaId: string;
	selectedPhysicianId: number | string;
}

const validations: ValidatorMapProps[] = [
	{
		name: 'selectedPhysicianId',
		required: true,
		errorMessage: 'You must select or add a KOL before continuing.',
		validate: (value, validators) => {
			if (value === '') return false;
			if (value === 0 && !isNaN(value)) return false;
			return true;
		}
	},
	{
		name: 'beliefs',
		required: true,
		isArray: true,
		appendName: 'belief',
		errorMessage: 'You must select a value.',
		validate: (value, validators) => {
			return value.value === null || !isNaN(value.value)
		}
	},
	{
		name: 'answers',
		required: true,
		isArray: true,
		appendName: 'answer',
		errorMessage: 'You must select a value.',
		validate: (value, validators) => {
			return (value !== null && value.value !== null && value.value.length > 0);
		}
	},
	{
		name: 'sourceOfInsight',
		required: true,
		errorMessage: 'You must select a Source of Insight.',
		validate: (value, validators) => {
			return (value !== null && value !== '')
		}
	},
]

export interface InsightBeliefProps {
	id: number;
	value: number;
}

export interface InsightAnswerProps {
	id: number;
	questionId: number;
	value: string[];
}

const steps = ['Select Physician', 'MCP Rating(s)'];

const InsightsDialog = (props: InsightsDialogProps) => {

	const { validate, errors } = useValidator(validations);
	const { diseaseArea, years, quarters, currentQuarter } = props;
	const dispatch = useAppDispatch();

	const insight = useAppSelector(selectInsight);

	const [hasCreatedInitialData, setHasCreatedInitialData] = useState(false)
	const [activeStep, setActiveStep] = useState(0);
	const [completed] = useState<{
		[k: number]: boolean;
	}>({});

	const [formData, setFormData] = useState<InsightFormProps>({
		diseaseAreaId: diseaseArea.id,
		selectedPhysicianId: ''
	} as InsightFormProps);

	// const [showExistingPhysicianInfo, setShowExistingPhysicianInfo] = useState(false);
	// data collected from the dropdown form items.
	const [sourceOfInsight, setSourceOfInsight] = useState<string>('');
	const [year, setYear] = useState<number>(diseaseArea.year);
	const [quarter, setQuarter] = useState<number>(currentQuarter);
	const [generalNotes, setGeneralNotes] = useState<string>('');
	const [followUpActionNotes, setFollowUpActionNotes] = useState<string>('');
	// setup the belief and answer data for a controlled state.
	const [beliefData, setBeliefData] = useState<InsightBeliefProps[]>(diseaseArea.beliefs.map(x => { return { id: x.id, value: Constants.BeliefNullID }}));
	const [answerData, setAnswerData] = useState<InsightAnswerProps[]>(diseaseArea.questions.map(x => { return { id: x.id, questionId: x.id, value: [] }}));


	useEffect(() => {
		if (!hasCreatedInitialData && diseaseArea) {
			dispatch(getInsightSupportTables(diseaseArea.id)) // Get options for step 1 dropdowns
			setHasCreatedInitialData(true)
		}
	}, [hasCreatedInitialData, diseaseArea, setHasCreatedInitialData, dispatch ])

	const handleNext = () => {
		if (activeStep === 0 && !validate({ selectedPhysicianId: formData.selectedPhysicianId })) {
			return;
		}
		
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleClose = () => {
		handleReset();
		if (props.onClose) props.onClose();
	}

	const handleResetForm = () => {
		setBeliefData(diseaseArea.beliefs.map(x => { return { id: x.id, value: Constants.BeliefNullID }}));
		setAnswerData(diseaseArea.questions.map(x => { return { id: x.id, questionId: x.id, value: [] }}));
		setSourceOfInsight('');
		setGeneralNotes('');
		setFollowUpActionNotes('');
	}

	const handleSubmitNewPhysician = (physicianData: PhysicianFormProps) => {
		handleResetForm();
		dispatch(submitNewPhysician(physicianData)).then(data => {
			validate({
				selectedPhysicianId: Number(data.payload.createdPhysician.id)
			})

			// setShowExistingPhysicianInfo(false)
			setFormData({ ...formData, selectedPhysicianId: Number(data.payload.createdPhysician.id) })
			props.onPhysicianCreated()
		});
	}

	const handlePhysicianChange = (e: any) => {
		const value = Number(e.target.value);

		validate({
			selectedPhysicianId: value
		})

		const tempData = { ...formData, [e.target.name]: e.target.value }
		setFormData(tempData);

		handleResetForm();

		dispatch(getPhysicianInsightData(value)).then((data) => {
			if (data.payload) {
				
				let pastBeliefData = data.payload.beliefs.map((x: any) => {
					return {
						id: x.beliefId,
						value: x.value === null ? Constants.BeliefNullID : x.value
					}
				})
				
				beliefData.forEach(x => {
					let pastBelief = pastBeliefData.find((y: any) => y.id === x.id)
					if(pastBelief != null) {
						x.value = pastBelief.value
					}
				})

				setBeliefData(beliefData);

				let pastAnswers = data.payload.answers.map((x: any) => {
					return {
						id: x.questionId,
						questionId: x.questionId,
						value: x.value
					}
				})

				answerData.forEach(x => {
					let pastAnswer = pastAnswers.find((y: any) => y.questionId === x.questionId)
					if(pastAnswer != null) {
						x.value = pastAnswer.value
					}
				})

				setAnswerData(answerData);

				setSourceOfInsight(data.payload.sourceOfInsight)
				// setShowExistingPhysicianInfo(true);
			} 
			else 
			{
				// setShowExistingPhysicianInfo(false);
			}
		});
	}

	const handleSourceOfInsightChange = (e: any) => {
		setSourceOfInsight(e.target.value)
	}

	const handleYearChange = (e: any) => {
		setYear(e.target.value)
	}

	const handleQuarterChange = (e: any) => {
		setQuarter(e.target.value)
	}

	const handleBeliefChange = (e: any) => {
		const id = Number(e.target.name);
		let curBelief = beliefData.find(x => x.id === id)

		if(curBelief) {
			curBelief.value = Number(e.target.value)
			setBeliefData([...beliefData])
		}
	}

	const handleAnswerChange = (e: any) => {
		const id = Number(e.target.name);
		let curAnswer = answerData.find(x => x.questionId === id)

		if(!curAnswer) {
			curAnswer = { id: id, questionId: id, value: []}
			answerData.push(curAnswer)
		}

		curAnswer.value = e.target.value
		setAnswerData([...answerData])
	}

	const handleSubmit = () => {
		let insightData = {
			...formData,
			beliefs: [...beliefData].map(x => {
				return {
					...x,
					value: x.value === Constants.BeliefNullID ? null : x.value
				}
			}),
			answers: answerData,
			year: year,
			quarter: quarter,
			sourceOfInsight: sourceOfInsight,
			generalNotes: generalNotes,
			followUpActionNotes: followUpActionNotes
		};

		if (validate(insightData)) {
			dispatch(submitInsight(insightData)).then(() => {
				props.onSubmit && props.onSubmit(insightData);
				handleReset();
			});
		}
	}

	const handleGeneralNotesChange = (e: any) => {
		setGeneralNotes(e.target.value)
	}

	const handleFollowUpActionNotesChange = (e: any) => {
		setFollowUpActionNotes(e.target.value)
	}


	const BootstrapDialogTitle = (props: DialogTitleProps) => {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	};

	const GetStepContent = (index: number) => {
		switch (index) {
			case 0:
				return (
					<>
						<Grid container spacing={2}>
							<Grid item sm={12} md={6}>
								<Typography variant="body1">
									<strong>Select an existing KOL</strong>
								</Typography>
								<br />
								<FormControl fullWidth size="small" sx={{ mb: 2 }} required error={errors['selectedPhysicianId'] ? true : false}>
									<InputLabel id="physician-name-select-label">KOL</InputLabel>
									<Select
										labelId="physician-name-select-label"
										id="physician-name-simple-select"
										value={formData.selectedPhysicianId.toString()}
										name="selectedPhysicianId"
										label="KOL"
										onChange={handlePhysicianChange}
										size="small"
										error={errors['selectedPhysicianId'] ? true : false}
									>
										{
											insight.supportTables.physicians && insight.supportTables.physicians.map((physician: any, idx: number) => (
												<MenuItem value={physician.id} key={idx}>{physician.value}</MenuItem>
											))
										}
									</Select>
								</FormControl>
								{
									errors['selectedPhysicianId'] &&
									<Box sx={{ mb: 2 }}> 
										<Alert severity="error">You must select a KOL before continuing. If you do not see your KOL in the list, enter a new KOL in the <strong>Add a new KOL</strong> form.</Alert>
									</Box>
								}
							</Grid>
							<Grid item sm={12} md={6} >
								<NewPhysician
									diseaseAreaId={diseaseArea.id}
									supportTables={{
										populations: insight.supportTables.populations,
										regions: insight.supportTables.regions,
										influences: insight.supportTables.influences
									}}
									onSubmit={handleSubmitNewPhysician}
								/>

							</Grid>
						</Grid>
					</>
				)
			case 1:
				return (
					<>
						<Grid container spacing={2}>
							<Grid item sm={12} md={6}>
								<Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2 }}>
									<Typography component="div" variant="body2" sx={{ marginBottom: '8px' }}>Year</Typography>
									<FormControl fullWidth size="small" sx={{ marginBottom: '8px' }} error={errors['year'] ? true : false}>
										<InputLabel id={`year-label`}>Select a value</InputLabel>
										<Select
											labelId={`year-label`}
											id={`year-select`}
											name={'year'}
											label="Select a value"
											value={year}
											onChange={handleYearChange}
											size="small"
										>
											{
												years.map((option, idx) => (
													<MenuItem value={option.id} key={`option${idx}`}>{option.value}</MenuItem>
												))
											}
										</Select>
										{errors['year'] && <FormHelperText sx={{ mb: 1 }}>{errors['year'].message}</FormHelperText>}

									</FormControl>

									<Typography component="div" variant="body2" sx={{ marginBottom: '8px' }}>Quarter</Typography>
									<FormControl fullWidth size="small" error={errors['quarter'] ? true : false}>
										<InputLabel id={`quarter-label`}>Select a value</InputLabel>
										<Select
											labelId={`quarter-label`}
											id={`quarter-select`}
											name={'quarter'}
											label="Select a value"
											value={quarter}
											onChange={handleQuarterChange}
											size="small"
										>
											{
												quarters.filter(x => Number(x.id) > 0).map((option, idx) => (
													<MenuItem value={option.id} key={`option${idx}`}>{option.value}</MenuItem>
												))
											}
										</Select>
										{errors['quarter'] && <FormHelperText sx={{ mb: 1 }}>{errors['quarter'].message}</FormHelperText>}

									</FormControl>
								</Box>
							</Grid>
							<Grid item sm={12} md={6}>
								<Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2 }}>
									<Typography component="div" variant="body2" sx={{ marginBottom: '8px' }}>Source of Insight</Typography>
									<FormControl fullWidth required size="small" error={errors['sourceOfInsight'] ? true : false}>
										<InputLabel id={`source-of-insight-label`}>Select a value</InputLabel>
										<Select
											labelId={`source-of-insight-label`}
											id={`source-of-insight-select`}
											name={'sourceOfInsight'}
											label="Select a value"
											value={sourceOfInsight}
											onChange={handleSourceOfInsightChange}
											size="small"
											required
										>
											<MenuItem value='MSL Interaction'>MSL Interaction</MenuItem>
											<MenuItem value='Consult/Advisory Board'>Consult/Advisory Board</MenuItem>
											<MenuItem value='Other'>Other</MenuItem>
										</Select>
										{errors['sourceOfInsight'] && <FormHelperText sx={{ mb: 1 }}>{errors['sourceOfInsight'].message}</FormHelperText>}
									</FormControl>
								</Box>
								
							</Grid>
							
							<Grid item sm={12} md={6}>
								<Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2 }}>
									<Typography variant="h6" sx={{ mb: 2 }}>MCP Ratings</Typography>
									{
										diseaseArea &&
										diseaseArea.beliefs.map((value, idx) => (
											<Box key={idx}>
												<Typography component="div" variant="body2" sx={{ marginBottom: '8px' }}>{value.value}</Typography>

												<FormControl fullWidth size="small" key={idx} sx={{ mb: 2 }} error={errors['belief' + (value.id.toString())] ? true : false}>
													<InputLabel id={`belief-select-label-${idx}`}>Select a value</InputLabel>
													<Select
														labelId={`belief-select-label-${idx}`}
														id={`belief-simple-select-${idx}`}
														name={value.id.toString()}
														label="Select a value"
														value={beliefData.length > idx ? beliefData[idx].value : Constants.BeliefNullID}
														onChange={handleBeliefChange}
														size="small"
													>
														<MenuItem value={Constants.BeliefNullID}>N/A</MenuItem>
														{[2,1,0,-1,-2].map(x => <MenuItem value={x.toString()} key={`option${x}`}>{x}</MenuItem>)}
													</Select>
													{errors['belief' + value.id.toString()] && <FormHelperText sx={{ mb: 1 }}>{errors['belief' + value.id.toString()].message}</FormHelperText>}

												</FormControl>
											</Box>
										))
									}
								</Box>

							</Grid>
							{diseaseArea.questions && diseaseArea.questions.length > 0 &&
								<Grid item sm={12} md={6}>
									<Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2 }}>
										<Typography variant="h6" sx={{ mb: 2 }}>MC Responses</Typography>
										{diseaseArea.questions.map((question, idx) => (
											<Box key={idx}>
												<Typography variant="body2" sx={{ marginBottom: '8px' }}>{question.question}</Typography>
												<FormControl fullWidth size="small" sx={{ mb: 2 }} required error={errors['answer' + (question.id.toString())] ? true : false}>
													<InputLabel id={`question-select-label-${idx}`}>Select All That Apply</InputLabel>
													<Select
														labelId={`question-select-label-${idx}`}
														id={`question-select-select-${idx}`}
														value={answerData[idx].value}
														name={question.id.toString()}
														label="Select all that Apply *"
														onChange={handleAnswerChange}
														size="small"
														required
														multiple
													>
														{
															question.options.map((option, idx) => (
																<MenuItem value={option.id} key={`option${idx}`}>{option.value}</MenuItem>
															))
														}
													</Select>
													{errors['answer' + (question.id.toString())] && <FormHelperText sx={{ mb: 1 }}>{errors['answer' + (question.id.toString())].message}</FormHelperText>}

												</FormControl>
											</Box>
										))}
									</Box>
								</Grid>}
							<Grid item sm={12} md={12}>
								<Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2 }}>
									<Typography variant="h6" sx={{ mb: 2 }}>Notes</Typography>
									<TextField
										id="general-notes"
										label="General Notes"
										name="generalNotes"
										variant="outlined"
										size="medium"
										multiline
										fullWidth
										onChange={handleGeneralNotesChange}
										sx={{ mb: 2 }}
									/>
									<TextField
										id="follow-up-action-notes"
										label="Follow-up Action Items"
										name="followUpActionNotes"
										variant="outlined"
										size="medium"
										fullWidth
										multiline
										onChange={handleFollowUpActionNotesChange}
										sx={{ mb: 2 }}
									/>
								</Box>
							</Grid>
						</Grid>
					</>
				)
		}
	}

	return (

		<Dialog open={insight.dialogState} onClose={handleClose} onSubmit={handleSubmit} maxWidth="md" fullWidth>
			<BootstrapDialogTitle onClose={handleClose}>Add New Insight - {diseaseArea.name} - {diseaseArea.year}</BootstrapDialogTitle>

			<DialogContent>
				<DialogContentText sx={{ mb: 2 }}>
					Complete the form below to submit a new insight.
				</DialogContentText>

				<Box sx={{ width: '100%' }}>

					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							const stepProps: { completed?: boolean } = {};
							const labelProps: {
								optional?: React.ReactNode;
							} = {};
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					<div>
						{Object.keys(completed).length === steps.length ? (
							<>
								<Typography sx={{ mt: 2, mb: 1 }}>
									All steps completed - you're finished
								</Typography>
								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<Box sx={{ flex: '1 1 auto' }} />
									<Button onClick={handleReset}>Reset</Button>
								</Box>
							</>
						) : (

							<>
								<Box sx={{ p: 4 }}>
									{GetStepContent(activeStep)}
								</Box>

								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<Button
										color="inherit"
										disabled={activeStep === 0}
										onClick={handleBack}
										sx={{ mr: 1 }}
									>
										Back
									</Button>
									<Box sx={{ flex: '1 1 auto' }} />

									{
										activeStep === steps.length - 1
											? <Button onClick={handleSubmit} variant="contained">Submit</Button>
											: <Button onClick={handleNext}>Next</Button>
									}

								</Box>
							</>
						)}
					</div>
				</Box>
			</DialogContent>
		</Dialog>

	);
}

export default InsightsDialog;