export {default as AppThemeProvider} from './app-theme-provider';
export * from './app-theme-provider';

// @note - adding new theme names will require a change in logic with the CustomThemeProvider
export type ThemeOptions = 'light' | 'dark';

// eslint-disable-next-line import/first
import { Theme } from '@mui/material';
export interface IAppThemeCollection {
  [key: string]: () => Theme;
}

// eslint-disable-next-line import/first
import {orange} from '@mui/material/colors';

// eslint-disable-next-line import/first
import { createTheme } from '@mui/material/styles';

const AppThemes : IAppThemeCollection = {
    "light": () : Theme => {
      return createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1200,
            xl: 1536,
          },
        },
        palette: {
          mode: 'light',
          neutral: {
            contrastText: '#ffffff',
            dark: 'rgb(27, 35, 48)',
            main: '#252f3e'
          },
          // primary: {
          //   contrastText: '#fff',
          //   main: '#e1242a'
          // },
          secondary: {
            contrastText: '#fff',
            main: '#e1242a'
          },
          background: {
            default: '#f6f7f9'
          }
        },
        typography: {
          h1: {
            fontSize: '2rem',
            fontWeight: 'bold'
          },
          h5: {
            fontWeight: 'bold'
          },
          h6: {
            fontSize: '1.1rem'
          },
          fontFamily: [
            'montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        components: {
          MuiToolbar: {
            styleOverrides: {
              root: {
                '.MuiButton-text': {
                  border: '1px solid transparent',
                  padding: '5px 15px'
                }
              }
            }
          },
          MuiAppBar: {
            defaultProps: {
              color: 'secondary'
            }
          },
          MuiButton: {
            defaultProps: {
              color: 'secondary',
            },
          },
          MuiTabs: {
            defaultProps: {
              textColor: "secondary",
              indicatorColor: "secondary"
            }
          },
          MuiCardHeader: {
            defaultProps: {
              titleTypographyProps: {
                variant:"body1",
                fontWeight: 'bold',
                color: 'Body',
                textTransform: 'capitalize'
              },
              subheaderTypographyProps: {
                variant: "subtitle2"
              }
            }
          },
          MuiListItemButton: {
            defaultProps: {
              disableTouchRipple: true,
            },
          },
          MuiListSubheader: {
            defaultProps: {
              style: {fontWeight: 600}
            }
          },
          MuiSelect: {
            defaultProps: {
              
            },
            styleOverrides: {
              select: {
                '&.Mui-disabled': {
                  backgroundColor:'#eeeeee'
                }
              }
            }
          },
          MuiFormGroup: {
            defaultProps: {
              style: {
                marginBottom: "16px"
              }
            }
          },
          MuiTablePagination: {
            styleOverrides: {
              selectLabel: {
                margin: 0
              },
              displayedRows: {
                margin: 0
              }
            }
          }
        },
      })
    },
    "dark": () : Theme => {
      return createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1200,
            xl: 1536,
          },
        },
        palette: {
          mode: 'dark',
          neutral: {
            contrastText: '#ffffff',
            dark: '#4b4f5a',
            main: '#2a2d35',
          },
          primary: {
            contrastText: '#FFFFFF',
            main: '#e1242a'
          },
          warning: {
            contrastText: "#ffffff",
            main: orange[700]
          },
          background: {
            default: '#1e1d1e',
            paper: '#313031'
          }
        },
        typography: {
          fontSize: 14,
          htmlFontSize: 16,
          fontFamily: [
            'montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        // shape: {
        //   borderRadius: 16
        // },
        components: {
          
          MuiListItemButton: {
            defaultProps: {
              disableTouchRipple: true,
            },
          },
          MuiListSubheader: {
            defaultProps: {
              style: {fontWeight: 600}
            }
          },
          MuiFormGroup: {
            defaultProps: {
              style: {
                marginBottom: "16px"
              }
            }
          }
        },
      }) 
    }
  } 

declare module '@mui/material/styles' {
    interface Palette {
      neutral: Palette['primary'];
      toolbar: Palette['primary'];
    }
  
    // allow configuration using `createTheme`
    interface PaletteOptions {
      neutral?: PaletteOptions['primary'];
      toolbar?: PaletteOptions['primary']
    }
  }
  
  // Update the Button's color prop options
  declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
      neutral: true;
      toolbar: true;
    }
  }
  
  // helper method to return a created theme.
  export const getAppTheme = (themeName : ThemeOptions) : Theme => {
    return AppThemes[themeName]();
  }