import { useState, SyntheticEvent } from 'react';

import {
  Box,
  Card,
  CardContent,
  Container,
  Tabs,
  Tab,
  Alert,
  AlertTitle
} from '@mui/material';

import ManageContent from './disease-areas';
import ManageUsers from './user-access';
import ManageBusinessUnits from './business-units';
import { selectAdmin } from 'store/slices/admin';
import { selectBusinessUnit } from 'store/slices/business-unit';
import { useAppSelector } from 'app/hooks';
import Loader from 'components/loader';

function a11yProps(index: number) {
  return {
    id: `admin-tab-${index}`,
    'aria-controls': `admin-tabpanel-${index}`,
  };
}

const AdminDashboard = () => {

  const [tabIdx, setTabIdx] = useState(0);
  const adminStore = useAppSelector(selectAdmin);
  const businessUnitStore = useAppSelector(selectBusinessUnit);
  
  return (
    <>
    {!adminStore.currentUser || businessUnitStore.businessUnits === undefined ? <Loader></Loader>
    :
    <Container>
      {(adminStore.currentUser?.isAdmin ?? false) ? 
        <Card>
          <CardContent>
            <Box sx={{ margin: '0 0 16px;', flexGrow:1 }}>
              <Tabs value={tabIdx} onChange={(event: SyntheticEvent, newValue: number) => setTabIdx(newValue) } area-label="Tabs">
                <Tab label={businessUnitStore.selectedBusinessUnit!.name + " Disease Areas"} {...a11yProps(0)} />
                <Tab label="User Access" {...a11yProps(1)} />
                <Tab label="Business Units" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <Box sx={{ margin: '16px 0;', flexGrow:1 }}>
              {tabIdx === 0 && <ManageContent />}
              {tabIdx === 1 && <ManageUsers />}
              {tabIdx === 2 && <ManageBusinessUnits />}
            </Box>
          </CardContent>
        </Card>
      :
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Alert severity="error" sx={{width:'80%'}}>
            <AlertTitle>Forbidden</AlertTitle>
            You do not have access to this content.
          </Alert>
        </Box>
      }
    </Container>
    }
    </>
  );
}

export default AdminDashboard;