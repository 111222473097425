import { FilterProps } from "pages/dashboard/dashboard";
import { fetchWithAuth } from "./request";

export default class AdminClient {

    public async getDashboardYearsAsync(accessToken: string, id: string): Promise<any> {
        return await fetchWithAuth(accessToken, `/api/dashboard/years?id=${encodeURIComponent(id)}`, {
            method: 'GET',
            redirect: 'error',
            headers: { 'Content-Type': 'application/json' },
          })
          .then(result => {
            if(!result.ok) throw new Error(result.statusText)
            return result.json()
          })
          .catch(ex => {
            console.error(ex);
          })
    }

    public async getDashboardWithFilters(accessToken: string, filters: FilterProps): Promise<any> {
      return await fetchWithAuth(accessToken, `/api/dashboard/data-with-support-tables?id=${encodeURIComponent(filters.diseaseArea.id)}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(filters)
      })
      .then(result => {
          if(!result.ok) throw new Error(result.statusText)
          return result.json();
      })
      .catch(error=> console.error(error));
  }
}