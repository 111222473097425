import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { BusinessUnitProps, DiseaseAreaListProps } from '../../utils/types';
import BusinessUnitsClient from 'api/business-unit-client'
import Config from 'utils/config'
import DiseaseAreaClient from '../../api/disease-area-client';

export interface BusinessUnitState {
  status: 'idle' | 'loading' | 'failed';
  diseaseAreaStatus: 'idle' | 'loading' | 'failed';
  selectedBusinessUnitId: string | null;
  selectedBusinessUnit: BusinessUnitProps | null;
  businessUnits: BusinessUnitProps[] | undefined;
  diseaseAreas: DiseaseAreaListProps[];
}

const initialState : BusinessUnitState = {
  status: 'loading',
  diseaseAreaStatus: 'loading',
  selectedBusinessUnitId: localStorage.getItem(Config.BUSINESS_UNIT_KEY) ?? '',
  selectedBusinessUnit: null,
  businessUnits: undefined,
  diseaseAreas: [] as DiseaseAreaListProps[]
}

export const fetchBusinessUnits = createAsyncThunk(
  'businessUnit/fetchBusinessUnits',
  async(_args, { getState }) => {
    const state = getState() as RootState
    return await new BusinessUnitsClient().getBusinessUnitsAsync(state.admin.accessToken)
  }
)

export const getDiseaseAreas = createAsyncThunk(
  'admin/manageContent/getDiseaseAreas',
  async (id: string, { getState }) => {
	const state = getState() as RootState
	return await (new DiseaseAreaClient()).getFilteredDiseaseAreasAsync(state.admin.accessToken, id)
  }
)

export const businessUnitSlice = createSlice({
  name: 'businessUnit',
  initialState,
  reducers: {
    setBusinessUnit: (state, action : PayloadAction<string>) => {
      const id = action.payload;

      if(state.businessUnits === undefined || state.businessUnits === null) {
        return;
      }

      state.selectedBusinessUnitId = id
      localStorage.setItem(Config.BUSINESS_UNIT_KEY, state.selectedBusinessUnitId);

      if(state.businessUnits.some(x => x.id === id)) {
        state.selectedBusinessUnit = state.businessUnits.find(x => x.id === id)!;
      }
    },
    setDiseaseArea: (state, action : PayloadAction<DiseaseAreaListProps[]>) => {
			state.diseaseAreas = action.payload;
		}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessUnits.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBusinessUnits.fulfilled, (state, action) => {
        state.status = 'idle';
        state.businessUnits = action.payload as any;

        if(state.selectedBusinessUnitId !== null) {
          let bu = action.payload.find((x: BusinessUnitProps) => x.id === state.selectedBusinessUnitId!) ?? null
          state.selectedBusinessUnit = bu ?? null

          if(bu === null) { // selected business unit id doesn't exist in list of business units for the user
            state.selectedBusinessUnitId = action.payload.length > 0 ? action.payload[0].id : null
            state.selectedBusinessUnit = action.payload.length > 0 ? action.payload[0] : null
          }

        } else if(state.selectedBusinessUnitId === null && action.payload.length > 0) {
          state.selectedBusinessUnitId = action.payload[0].id
          state.selectedBusinessUnit = action.payload.find((x: BusinessUnitProps) => x.id === state.selectedBusinessUnitId!) ?? null
        }
      })
      .addCase(fetchBusinessUnits.rejected, (state)=> {
        state.status = 'failed';
      })
      .addCase(getDiseaseAreas.pending, (state) => {
        state.diseaseAreaStatus = 'loading';
      })
      .addCase(getDiseaseAreas.fulfilled, (state, action) => {
        state.diseaseAreaStatus = 'idle';
        state.diseaseAreas = action.payload;
      })
      .addCase(getDiseaseAreas.rejected, (state) => {
        state.diseaseAreaStatus = 'failed';
      });
  }
});

export const selectBusinessUnit = (state : RootState) => state.businessUnit;

export const { 
  setBusinessUnit,
  setDiseaseArea 
} = businessUnitSlice.actions;

export default businessUnitSlice.reducer;