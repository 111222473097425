import download from "downloadjs";
import { fetchWithAuth } from "./request";

export default class InsightsClient {

    public async getInsightSupportTables(accessToken: string, diseaseAreaId: string): Promise<any> {
        return await fetchWithAuth(accessToken, `/api/insights/all-support-tables?id=${diseaseAreaId}`, {
			method: 'GET',
		})
		.then(result => {
			if(!result.ok) throw new Error(result.statusText)
			return result.json()
		})
    }

    public async getPhysicianInsightData(accessToken: string, id: number): Promise<any> {
        return await fetchWithAuth(accessToken, `/api/insights/getPhysicianInsights?id=${id}`, {
			method: 'GET'
		})
		.then(result => {
			if(!result.ok) return;
			return result.json();
		});
    }

    public async postInsight(accessToken: string, body: any): Promise<any> {
        return fetchWithAuth(accessToken, '/api/insights/submit', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body)
		})
		.then(result=> {
			if(!result.ok) throw new Error(result.statusText)
			return result.json();
		})
		.catch((error) => {
			console.error('An error occured while submitting insights:', error);
		})
    }

    public async postPhysician(accessToken: string, body: any): Promise<any> {
        return await fetchWithAuth(accessToken, '/api/physician', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body)
		})
		.then(result => {
			if(!result.ok) throw new Error(result.statusText)
			return result.json();
		})
		.catch((error) => {	
			console.error("error: " + error);
		});
    }

    public async exportDiseaseArea(accessToken: string, queryFilters: any, filename: string): Promise<any> {
        return await fetchWithAuth(accessToken, `/api/insights/export?${new URLSearchParams(queryFilters)}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
		}).then(res => {
			return res.blob();
		}).then(data => {
			download(data, filename,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		})
    }

	public async getPreview(accessToken: string, queryFilters: any): Promise<any> {
		if (queryFilters.startDate === undefined){
			queryFilters.startDate = "";
		}
		if (queryFilters.stopDate === undefined){
			queryFilters.stopDate = "";
		}

		return await fetchWithAuth(accessToken,`/api/insights/preview?${new URLSearchParams(queryFilters)}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
		.then(result => {
			if(!result.ok) throw new Error(result.statusText)
			return result.json();
		})
		.catch(error=> console.error(error));
	  }
}