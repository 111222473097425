import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from '@mui/material'

export interface SuccessDialogProps {
  open: boolean;
  guid: string | undefined;
}

const SuccessDialog = ({ open, guid } : SuccessDialogProps) => {
  return(
    <Dialog open={open} onBackdropClick={(event : any) => event.preventDefault() }>
      <DialogTitle>Success</DialogTitle>
      <DialogContent>
        <DialogContentText>{!!guid ? "Disease area has been updated" : "You have created a new disease area!"}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button component={Link} to="/admin" variant="contained">Continue</Button>
      </DialogActions>
    </Dialog>
  )
};

export default SuccessDialog;