import { ReactNode } from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'


export interface DeleteDialogProps {
	open: boolean,
	id: any;
	onSubmit: (value : any) => void;
	onClose: () => void;
	children?: ReactNode;
}

const DeleteDialog = (props: DeleteDialogProps) => {

	const handleSubmit = () => {
		if(props.onSubmit) props.onSubmit(props.id);
	}

	const handleClose = () => {
		if(props.onClose) props.onClose();
	}

	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			onSubmit={handleSubmit}
		>
			<DialogTitle id="delete-dialog-title">Are you sure you want to delete?</DialogTitle>
			<DialogContent>
				<DialogContentText component="div">
					{props.children}	
				</DialogContentText>
			</DialogContent>
			<DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus variant="contained">
            Delete
          </Button>
        </DialogActions>
		</Dialog>
	)
}

export default DeleteDialog;