import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import InsightsClient from '../../api/insights-client';
import { SupportTableProps } from '../../utils/types';

export interface DiseaseAreaExportProps {
	id: string;
	filename: string;
}

export type StatusStates = 'idle' | 'loading' | 'failed';

export interface InsightSupportTableProps {
	populations: SupportTableProps[];
  influences: SupportTableProps[];
  regions: SupportTableProps[];
	physicians: SupportTableProps[];
}

export interface InsightState {
	status: StatusStates;
	dialogState: boolean;
	supportTables: InsightSupportTableProps;
}

const initialState : InsightState = {
	status: 'idle',
	dialogState: false,
	supportTables: {
		populations: [],
		influences: [],
		regions: [],
		physicians: []
	}
}

export const getInsightSupportTables = createAsyncThunk(
	'insights/getSupportTables',
	async (diseaseAreaId: string, { getState }) => {
		const state = getState() as RootState
		return await (new InsightsClient()).getInsightSupportTables(state.admin.accessToken, diseaseAreaId)
	}
)

export const submitNewPhysician = createAsyncThunk (
	'insight/submitNewPhysician',
	async (data: any, { getState }) => {
		const state = getState() as RootState
		return await (new InsightsClient()).postPhysician(state.admin.accessToken, data)
	}
)

export const submitInsight = createAsyncThunk (
	'insights/submitInsight', 
	async(data: any, { getState }) => {
		const state = getState() as RootState
		return await (new InsightsClient()).postInsight(state.admin.accessToken, data)
	}
)

export const getPhysicianInsightData = createAsyncThunk(
	'insights/getPhysicians',
	async(id: number, { getState }) => {
		const state = getState() as RootState
		return await (new InsightsClient()).getPhysicianInsightData(state.admin.accessToken, id)
	}
)

export const insightSlice = createSlice({
	name:'insight',
	initialState,
	reducers: {
		setDialogState: (state, action: PayloadAction<boolean>) => {
			state.dialogState = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(submitNewPhysician.pending, (state) => {
			state.status = 'loading'
		})
		.addCase(submitNewPhysician.fulfilled, (state, action) => {
			state.status = 'idle';
			state.supportTables.physicians = action.payload.physicians;
		})
		.addCase(submitNewPhysician.rejected, (state) => {
			state.status = 'failed'
		})
		.addCase(submitInsight.pending, (state) => {
			state.status = 'loading';
		})
		.addCase(submitInsight.fulfilled, (state, action) => {
			state.status='idle';
		})
		.addCase(submitInsight.rejected, (state) => {
			state.status ='failed';
		})
		.addCase(getPhysicianInsightData.pending, (state) => {
			state.status = 'loading';
		})
		.addCase(getPhysicianInsightData.fulfilled, (state, action) => {
			state.status = 'idle';
		})
		.addCase(getPhysicianInsightData.rejected, (state) => {
			state.status = 'failed';
		})
		.addCase(getInsightSupportTables.pending, (state)=> {
			state.status = "loading";
		})
		.addCase(getInsightSupportTables.fulfilled, (state, action : PayloadAction<any>)=> {
			state.status = 'idle';
			state.supportTables = action.payload;
		})
		.addCase(getInsightSupportTables.rejected, (state) => {
			state.status = "failed";
		})
	}
});

export const { setDialogState } = insightSlice.actions;

export const selectInsight = (state : RootState) => state.insight;

export default insightSlice.reducer;