import {
  Box, Grid, Typography
} from '@mui/material'

import {
  TrendingUp as TrendingUpIcon,
  Groups as GroupsIcon
} from '@mui/icons-material'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { DiseaseAreaProps } from '../utils/types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const formatLabel = (str: string, maxWidth: number): string[] => {
  const sections = [] as string[];
  const words = str.split(' ');
  let temp = '';

  words.forEach((word, index) => {
    if (temp.length > 0) {
      let concat = temp + ' ' + word;

      if (concat.length > maxWidth) {
        sections.push(temp);
        temp = "";
      }
      else {
        if (index === (words.length - 1)) {
          sections.push(concat);
          return;
        }
        else {
          temp = concat;
          return;
        }
      }
    }

    if (index === (words.length - 1)) {
      sections.push(word);
      return;
    }

    if (word.length < maxWidth) {
      temp = word;
    }
    else {
      sections.push(word);
    }

  });

  return sections;
}

const generateBgColors = (data: number[]) => {
  const bgColors = [];
  const borderColors = [];

  for (let i = 0; i < data.length; i++) {
    const value = data[i];
    if (value > 0) {
      bgColors.push('rgba(33, 150, 243, 0.75)');
      borderColors.push('rgba(33, 150, 243, 1)');
    } else {
      bgColors.push('rgba(255, 36, 42, 0.75)');
      borderColors.push('rgba(255, 36, 42, 1)')
    }
  }
  return {
    backgroundColors: bgColors,
    borderColors: borderColors
  };
}

export interface BeliefChartProps {
  diseaseArea: DiseaseAreaProps;
  totalNumOfInsights: number;
  overallBeliefScore: number | null;
}

const BeliefChart = (props: BeliefChartProps) => {

  const { diseaseArea } = props;

  const labels = [] as string[][];
  const data = [] as number[];

  const options = {
    indexAxis: 'y' as const,
    // responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      x: {
        min: -2,
        max: 2,
        grid: {
          color: (line: any) => {
            return (line.tick.value === 0 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)')
          }
        }
      },
    }
  }

  diseaseArea?.beliefs.forEach((item, index) => {
    labels.push(
      formatLabel(item.value as string, 40)
    )
    data.push(item.data ? item.data : 0);
  });

  const colors = generateBgColors(data)

  const chartData = {
    labels,
    datasets: [{
      label: '',
      data: data,
      borderWidth: 2,
      backgroundColor: colors.backgroundColors,
      borderColor: colors.borderColors,
      hoverBackgroundColor: colors.borderColors
    }]
  }

  return (
    <>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 0, mr: 2 }}>
              <GroupsIcon fontSize='large' />
            </Box>
            <Box>
              <Typography variant="body1" color="GrayText" sx={{ lineHeight: 1 }}>Total # of Insights</Typography>
              <Typography variant="h6">
                <strong>{props.totalNumOfInsights}</strong>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 0, mr: 2 }}>
              <TrendingUpIcon fontSize='large' />
            </Box>
            <Box>
              <Typography variant="body1" color="GrayText" sx={{ lineHeight: 1 }}>Overall MCP Rating</Typography>
              <Typography variant="h6">
                <strong>{props.overallBeliefScore ? props.overallBeliefScore.toFixed(2) : 0}</strong>
              </Typography>
            </Box>
          </Box>

        </Grid>
      </Grid>

      <Box sx={{ overflow: 'auto' }}>
        <Box sx={{minWidth:700}}>
          <Bar options={options} data={chartData} />
        </Box>
      </Box>
    </>
  )
}

export default BeliefChart;