import {
  Box,
  Alert,
  AlertTitle
} from '@mui/material'

const SelectDiseaseAreaWarning = () => {
  return(
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Alert severity="warning" sx={{width:'80%'}}>
        <AlertTitle>Warning</AlertTitle>
        You must select a disease area.
      </Alert>
    </Box>
  )
}

export default SelectDiseaseAreaWarning;