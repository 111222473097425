import { useState } from 'react';

export interface ValidatorMapProps {
  name: string;
  required?: boolean;
  errorMessage: string;
  appendName?: string;
  isArray?: boolean;
  validate: (value : any, validators : Validators, validatorData : ValidatorMapProps) => boolean;
}

export interface ValidatorMap {
  [key: string] : ValidatorMapProps
}

export interface Validators {
  isStringNullOrWhitespace: (value: string) => boolean;
  isDate: (value : Date) => boolean;
}

const validators : Validators = {
  isStringNullOrWhitespace: (value : string) : boolean => {
    if(!value) return true;
    if(value && value.length === 0) return true;

    return false;
  },
  isDate: (value: Date) : boolean => {
    return value && Object.prototype.toString.call(value) === "[object Date]" && !isNaN(value.getDate());
  }
}

export const useValidator = (validatorData : ValidatorMapProps[]) => {

  const [errors, setErrors] = useState<any>({});

  function validate(data : any) : boolean {
    const temp = {} as any;
    for(let i = 0; i < validatorData.length; i++) {

      const validator = validatorData[i];
      
      if(validator.required) {
        const valueToValidate = data[validator.name];
        if(valueToValidate === undefined || valueToValidate === null) continue;

        if(validator.isArray === true) {
          
          for(let j = 0; j < valueToValidate.length; j++) {

            const result = validator.validate(valueToValidate[j], validators, validatorData[i]);
            const name = validatorData[i].appendName ? (validatorData[i].appendName + valueToValidate[j].id) : valueToValidate[j].name
            
            if(result === false) {
              temp[name] = {
                name: name,
                message: validator.errorMessage
              }
            }
          }
        }
        else {
          const result = validator.validate(valueToValidate, validators, validatorData[i]);
          if(result === false) {
            temp[validator.name] = {
              name: validator.name,
              message: validator.errorMessage
            }
          }
        }
      }
    }
    
    setErrors(temp);

    return Object.keys(temp).length === 0;
  }

  return {
    errors,
    validate,
    isValid: Object.keys(errors).length === 0
  }
}

