import { useEffect, useState, useRef } from 'react';
import { useValidator, ValidatorMapProps } from 'features/validator'

import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button,

} from '@mui/material';
import Config from 'utils/config';

import {
  Add as AddIcon,
} from '@mui/icons-material';
import { OptionProps, QuestionProps } from 'utils/types';

export interface QuestionDialogProps {
  open: boolean;
  editMode: boolean;
  question?: QuestionProps;
  editId?: number;
  onSubmit: (value: QuestionProps, index: number) => void;
  onClose: () => void;
}

export interface QuestionOptionProps {
  id: number;
  name: string;
  value: string;
}

const minOptions = 2;
const optionNames = ['option1', 'option2'];

const validations : ValidatorMapProps[] = [
  {
    name: 'question',
    required: true,
    errorMessage: 'You must enter a question.',
    validate: (value, validators) => {
      return !validators.isStringNullOrWhitespace(value);
    }
  },
  {
    name: 'options',
    required: true,
    isArray: true,
    errorMessage: 'You must enter a value for this option.',
    validate: (value : any, validators) => {
      if(value && value.required !== false) {
        return !validators.isStringNullOrWhitespace(value.value);
      }
      return true;
    }
  }
]

const QuestionDialog = (props: QuestionDialogProps) => {

  // ensures option data is only loaded once.
  const hasLoadedOptions = useRef(false);

  const [question, setQuestion] = useState(props.question ? props.question.question : '');
  const [options, setOptions] = useState<OptionProps[]>(props.question ? props.question.options : []);

  const { validate, errors } = useValidator(validations);

  useEffect(() => {
    if(!!hasLoadedOptions.current) {
      return 
    }

    const optionArr = [] as OptionProps[];
    if (options.length === 0) {
      // add the NA option by default. This option does not appear in the question options fields.
      optionArr.push({id: 0, value: 'N/A'} as OptionProps)
      for (let i = 0; i < optionNames.length; i++) {
        optionArr.push({ id: 0, name: optionNames[i], value: '', required: true })
      }
    } else {
      for (let i = 0; i < options.length; i++) {
        options[i].name = `option${i}`;
        if(i < 3) { // Makes the first 2 options required since N/A will be option 0 but it's hidden in the UI
          options[i].required = true;
        }
        optionArr.push(options[i]);
      }
    }

    hasLoadedOptions.current = true;
    setOptions(optionArr)
  }, [options, props.editMode]);


  const handleBackdropClick = (e : any) => {
    e.preventDefault();
  }

  const handleAddOption = () => {
    const newIdx = options.length + 1;
    setOptions(arr => [...arr, { id: 0, name: `option${newIdx}`, value: '', required: false }] as OptionProps[]);
  }

  const handleClose = () => {
    props.onClose && props.onClose();
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData: QuestionProps = {
      id: props.question ? props.question.id : 0,
      question,
      options
    };

    if(validate(formData)) {
      props.onSubmit && props.onSubmit(formData, props.editId !== undefined ? props.editId : -1)
    }
  }

  const handleChange = (e: any) => {
    setQuestion(e.target.value);
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.name === name) {
        // found an existing name, update that item in the array.
        setOptions(arr => {
          if (option.name === name) {
            arr[i].name = name;
            arr[i].value = value;
            arr[i].required = option.required
          }
          return arr;
        });
        return;
      }
    }
    // new value being added so no id will be associated yet.
    setOptions(arr => [...arr, { id: null, name: name, value: value, required: false }] as QuestionOptionProps[]);
  }

  function GenerateOptionAddButton() {
    // add one to compinsate for the hidden "N/A" option.
    if(options.length < Config.MAX_DISEASE_OPTIONS +1) {
      return (<Button variant="contained" startIcon={<AddIcon />} onClick={handleAddOption}>Add Option</Button>)
    }
    return (<Button variant="contained" startIcon={<AddIcon />} disabled>Add Option</Button>)
  }

  function GenerateOptionInputs() {
    
    // we are modifying the options.
    if (props.editMode) {
      return (
        <>
          {
            options.map((option, index) => (
              option.value !== 'N/A' &&
              <TextField
                key={index}
                fullWidth
                name={option.name}
                label={`Option ${index}`}
                variant="outlined"
                defaultValue={option.value}
                required={option.required}
                sx={{ mb: 2 }}
                onChange={handleOptionChange}
                error={errors[option.name] ? true : false}
                helperText={errors[option.name] ? errors[option.name].message : ''}
              />
            ))
          }
        </>
      );
    }
    return (
      <>
        {
          options.map((option, index) => (
            option.value !== 'N/A' &&
            <TextField
              key={index}
              fullWidth
              name={option.name}
              label={`Option ${index}`}
              variant="outlined"
              required={index < minOptions ? true : false}
              sx={{ mb: 2 }}
              defaultValue={options[index].value}
              onChange={handleOptionChange}
              error={errors[option.name] ? true : false}
              helperText={errors[option.name] ? errors[option.name].message : ''}
            />
          ))
        }

      </>
    )
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      aria-labelledby="question-dialog-title"
      aria-describedby="question-dialog-description"
    >
      <DialogTitle id="question-dialog-title">
        New Question &amp; and Answers.
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText id="question-dialog-description" sx={{ mb: 2 }}>
            Enter a question followed by up to <strong>{Config.MAX_DISEASE_OPTIONS}</strong> response options.
          </DialogContentText>

          <TextField
            fullWidth
            label="Question"
            name="question"
            variant="outlined"
            autoFocus
            defaultValue={question}
            required
            sx={{ mb: 2 }}
            onChange={handleChange}
            error={errors.question ? true : false }
            helperText={errors.question ? errors.question.message : ''}
          />

          <DialogContentText id="question-dialog-description" sx={{ mb: 2 }}>
            Enter the options for the question.
          </DialogContentText>

          <Box id="question-options-container">
            <GenerateOptionInputs />
          </Box>

          <GenerateOptionAddButton />
          {/* <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddOption}>Add Option</Button> */}

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default QuestionDialog;