import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dashboardReducer from './slices/dashboard'
import AdminReducer from './slices/admin';
import InsightReducer from './slices/insight';
import businessUnitReducer from './slices/business-unit';

export const store = configureStore({
  reducer: {
    businessUnit: businessUnitReducer,
    dashboard: dashboardReducer,
    admin: AdminReducer,
    insight: InsightReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
