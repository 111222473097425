
// client side version of the application settings.
// these variables should be updated in both AppSettings.cs and .env
const config = {
    THEME_STORAGE_KEY: String(process.env.REACT_APP_THEME_STORAGE_KEY),
    BUSINESS_UNIT_KEY: String(process.env.REACT_APP_BUSINESS_UNIT_KEY),
    FILTERS_KEY: String(process.env.REACT_APP_FILTERS_KEY),

    MAX_BUSINESS_UNITS: Number(process.env.REACT_APP_MAX_BUSINESS_UNITS),
    MAX_BELIEFS: Number(process.env.REACT_APP_MAX_BELIEFS),
    MAX_DISEASE_AREAS: Number(process.env.REACT_APP_MAX_DISEASE_AREAS),
    MAX_DISEASE_AREAS_QUESTIONS: Number(process.env.REACT_APP_MAX_DISEASE_AREAS_QUESTIONS),
    MAX_DISEASE_OPTIONS: Number(process.env.REACT_APP_MAX_DISEASE_AREAS_OPTIONS),

    oidc: {
        clientId: '0oahrecdwkIpOFaES357', // String(process.env.OIDC_CLIENT_ID),
        issuer: 'https://takeda.okta.com/oauth2/ausl3bs526YcmqHoZ357', // String(process.env.OIDC_ISSUER),
        redirectUri: '/login/callback', // String(process.env.OIDC_REDIRECT_URI),
        scopes: ['openid', 'profile', 'email', 'groups'], // String(process.env.OIDC_SCOPES),
        pkce: true
    },

    ADMIN_GROUP: "Okta-MedInsights-Admins"
}

export default config