import * as React from 'react';

import {
  Box,
  Button,
  FormControl,
  TextField,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Typography
} from '@mui/material'
import { SupportTableProps } from '../utils/types';
import { useValidator, ValidatorMapProps } from './validator';

export interface PhysicianFormProps {
	name: string;
	region: string | number;
	influence: string | number;
	population: string | number;
	diseaseAreaId: string;
}

export interface NewPhysicianProps {
  diseaseAreaId: string;
  supportTables: any;
  onSubmit: (value : PhysicianFormProps) => void;
}

const validations : ValidatorMapProps[] = [
  {
    name: 'name',
    required: true,
    errorMessage: 'You must enter a KOL name.',
    validate: (value, validators) => {
      return !validators.isStringNullOrWhitespace(value);
    }
  },
  {
    name: 'region',
    required: true,
    errorMessage: 'You must enter a region.',
    validate: (value, validators) => {
      return value !== '';
    } 
  },
  {
    name: 'influence',
    required: true,
    errorMessage: 'You must enter an HCP Tier.',
    validate: (value, validators) => {
      return value !== '';
    }
  },
  {
    name: 'population',
    required: true,
    errorMessage: 'You must enter an population.',
    validate: (value, validators) => {
      return value !== '' 
    }
  },
]

const NewPhysician = (props: NewPhysicianProps) => {

  const [formData, setFormData] = React.useState<PhysicianFormProps>({
		name: '',
		region: '',
		influence: '',
		population: '',
		diseaseAreaId: props.diseaseAreaId
	});

  const {validate, errors } = useValidator(validations);

  const handlePhysicianChange = (e: any) => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
		setFormData(newFormData);
    validate({[e.target.name]: e.target.value})
	}

  const handleSubmitNewPhysician = (e : any) => {
    e.preventDefault();
    if(validate(formData)) {
      props.onSubmit && props.onSubmit(formData)
      reset();
    }
  }

  const reset = () => {
    setFormData({
      name: '',
      region: '',
      influence: '',
      population: '',
      diseaseAreaId: formData.diseaseAreaId
    })
  }

  return (
    <Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2 }} component="form" onSubmit={handleSubmitNewPhysician}>
      <Typography variant="body1">
        <strong>
          Add a new KOL
        </strong>
        <small> (optional)</small>
      </Typography>
      <br />
      <TextField
        id="new-physician-name"
        label="KOL Name"
        name="name"
        defaultValue={formData.name}
        variant="outlined"
        size="small"
        fullWidth
        required
        onChange={handlePhysicianChange}
        sx={{ mb: 2 }}
        error={errors['name'] ? true : false}
        helperText={errors['name'] ? errors['name'].message : ''}
      />
      <FormControl fullWidth size="small" sx={{ mb: 2 }} required error={errors['region'] ? true : false}>
        <InputLabel id="new-region-select-label">Region in Canada</InputLabel>
        <Select
          labelId="new-region-select-label"
          id="new-region-simple-select"
          value={formData.region}
          name="region"
          label="Region in Canada"
          onChange={handlePhysicianChange}
          size="small"
        >
          {props.supportTables.regions.map((region: SupportTableProps, idx : number) => (
            <MenuItem value={region.id} key={idx}>{region.value}</MenuItem>
          ))}
        </Select>
        {errors['region'] && <FormHelperText>{errors['region'].message}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth size="small" sx={{ mb: 2 }} required error={errors['influence'] ? true : false}>
        <InputLabel id="new-influence-select-label">HCP Tier</InputLabel>
        <Select
          labelId="new-influence-select-label"
          id="new-influence-simple-select"
          value={formData.influence}
          name="influence"
          label="Influence"
          onChange={handlePhysicianChange}
          size="small"
        >
          {props.supportTables.influences.map((influence : SupportTableProps, idx : number) => (
            <MenuItem value={influence.id} key={idx}>{influence.value}</MenuItem>
          ))}
        </Select>
        {errors['influence'] && <FormHelperText>{errors['influence'].message}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth size="small" sx={{ mb: 2 }} required error={errors['population'] ? true : false}>
        <InputLabel id="new-population-select-label">Population</InputLabel>
        <Select
          labelId="new-population-select-label"
          id="new-population-simple-select"
          value={formData.population}
          name="population"
          label="Population"
          onChange={handlePhysicianChange}
          size="small"
        >
          {props.supportTables.populations.map((population : SupportTableProps, idx : number) => (
            <MenuItem value={population.id} key={idx}>{population.value}</MenuItem>
          ))}
        </Select>
        {errors['population'] && <FormHelperText>{errors['population'].message}</FormHelperText>}
      </FormControl>
      <Button variant="contained" onClick={handleSubmitNewPhysician}>Add KOL</Button>
    </Box>
  )
}

export default NewPhysician;