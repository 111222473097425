import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '..';

import {
  DiseaseAreaProps,
  PreviewProps,
  NotesForKOLProps,
  SupportTableProps
} from '../../utils/types';

import {
  FilterProps
} from 'pages/dashboard/dashboard'
import DashboardClient from "../../api/dashboard-client";
import DiseaseAreaClient from "../../api/disease-area-client";
import { DateTime } from "luxon";
import InsightsClient from "api/insights-client";
import { Dashboard } from "@mui/icons-material";

export type FilterPropType = {
  name: string;
  value: string;
}

export interface SupportTables {
  diseaseAreas: SupportTableProps[];
  populations: SupportTableProps[];
  influences: SupportTableProps[];
  regions: SupportTableProps[],
  physicians: SupportTableProps[]
  years: SupportTableProps[],
  quarters: SupportTableProps[]
}

export type StatusTypes = 'idle' | 'loading' | 'failed';

export interface DashboardState {
  status: StatusTypes;
  diseaseArea: DiseaseAreaProps | null;
  physicianSnackbar : boolean;
  insightSnackbar : boolean;
  supportTables: SupportTables;
  filters: FilterProps;
  totalNumOfInsights: number | null,
  overallBeliefScore: number | null,
  businessUnitDialogState: boolean; 
  currentQuarter: number;
  exportModal: boolean;
  preivewModal: boolean;
  preview: PreviewProps;
  notes: NotesForKOLProps;
}

const initialState : DashboardState = {
  status: 'idle',
  physicianSnackbar: false,
  insightSnackbar: false,
  diseaseArea: null,
  businessUnitDialogState: false,
  exportModal: false,
  preivewModal: false,
  totalNumOfInsights: null,
  overallBeliefScore: null,
  supportTables: {
    diseaseAreas: [],
    populations: [],
    influences: [],
    regions: [],
    physicians: [],
    years: [],
    quarters: []
  },
  filters: {
    diseaseArea: { id: '', value: '' } as SupportTableProps,
		year: { id: '', value: '' } as SupportTableProps,
		region: { id:'', value: '' } as SupportTableProps,
		influence: { id:'', value: '' } as SupportTableProps,
		population: { id:'', value: '' } as SupportTableProps,
    physician: { id:'', value: '' } as SupportTableProps,
    quarter: { id:'', value: '' } as SupportTableProps,
    lastSelected: '',
    startDate: null,
	  stopDate: null,
  },
  currentQuarter: 1,
  preview: {
    headers: [],
    lineItems: []
  },
  notes: {
    generalNotes: [],
    followUpNotes: []

  }
}

export const loadDashboardYears = createAsyncThunk(
  'dashboardYears/load',
  async (id : string, { getState }) => {
    const state = getState() as RootState
    return await (new DashboardClient()).getDashboardYearsAsync(state.admin.accessToken, id)
  } 
);

export interface DiseaseAreaSupportTablesProps {
  businessUnitId: string,
  year: number
}

// @todo - check if used, move to store
export const getDiseaseAreaSupportTables = createAsyncThunk(
  'dashboard/getDiseaseAreaSupportTables',
  async(value : DiseaseAreaSupportTablesProps, { getState }) => {
    const state = getState() as RootState
    return await (new DiseaseAreaClient()).getDiseaseAreaSupportTables(state.admin.accessToken, value)
  }
)

export const loadDashboardWithData = createAsyncThunk(
  'dashboard/loadDashboardWithData',
  async(filters: FilterProps, { getState }) => {
    const state = getState() as RootState
    return await (new DashboardClient()).getDashboardWithFilters(state.admin.accessToken, filters)
  }
);

export const LoadPreview = createAsyncThunk(
  'dashboard/LoadPreview',
  async(filters: FilterProps, { getState }) => {
    const state = getState() as RootState
    return await (new InsightsClient()).getPreview(state.admin.accessToken, filters)
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setBusinessUnitDialogState: (state, action : PayloadAction<boolean>) => {
      state.businessUnitDialogState = action.payload;
    },
    clearFilters: (state) => {
      state.filters.region = { } as SupportTableProps;
      state.filters.influence = { } as SupportTableProps;
      state.filters.population = { } as SupportTableProps;
      state.filters.physician = { } as SupportTableProps;
      state.filters.quarter = { } as SupportTableProps;
    },
    reset: (state) => {
      return {...initialState}
    },
    updateFilter: (state, action: PayloadAction<SupportTableProps>) => {

      if(action.payload.value.toLowerCase() === 'diseasearea') {
        state.filters.diseaseArea = action.payload;
      } 
      else if(action.payload.value.toLowerCase() === 'year'){
        state.filters.year = action.payload;
        state.filters.diseaseArea = { id: '', value: ''} as SupportTableProps;
      }
      else if(action.payload.value.toLowerCase() === 'region'){
        state.filters.region = action.payload
      }
      else if(action.payload.value.toLowerCase() === 'influence'){
        state.filters.influence = action.payload
      }
      else if(action.payload.value.toLowerCase() === 'population'){
        state.filters.population = action.payload
      }
      else if(action.payload.value.toLowerCase() === 'physician'){
        state.filters.physician = action.payload
      }
      else if(action.payload.value.toLowerCase() === 'quarter'){
        state.filters.quarter = action.payload
      }

      state.filters.lastSelected = action.payload.value;
    },

    updatePhysicians: (state, action: PayloadAction<SupportTableProps[]>) => {
      state.supportTables.physicians = action.payload;
      state.physicianSnackbar = true;
    },
    closePhysicianSnackbar: (state) => {
      state.physicianSnackbar = false;
    },
    setInsightSnackbarState: (state, action : PayloadAction<boolean>) => {
      state.insightSnackbar = action.payload;
    },
    closeExportModal: (state) => {
      state.exportModal = false;
    },
    openExportModal: (state) => {
      state.exportModal = true;
    },
    openPreviewModal: (state) => {
      state.preivewModal = true;
    },
    closePreviewModal: (state) => {
      state.preivewModal = false;
    },
    updateStartDate: (state, action: PayloadAction<DateTime>) => {
      state.filters.startDate = DateTime.fromJSDate((new Date(action.payload.toString()))).plus({ days: 1 });
    },
    updateStopDate: (state, action: PayloadAction<DateTime>) => {
      state.filters.stopDate = DateTime.fromJSDate(new Date(action.payload.toString())).plus({ days: 1 });
    },
    updatePreview: (state, action: PayloadAction<PreviewProps>) => {    
      state.preview = action.payload;
      state.preivewModal = true;
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDashboardWithData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadDashboardWithData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.diseaseArea = action.payload.diseaseArea;
        state.totalNumOfInsights = action.payload.totalNumOfInsights;
        state.overallBeliefScore = action.payload.overallBeliefScore;
        state.currentQuarter = action.payload.currentQuarter;
        state.notes = action.payload.notes;
        state.supportTables = {
          ...state.supportTables,
          ...action.payload.supportTables
        };
        // state.supportTables.physicians = action.payload.physicians;
      })
      .addCase(loadDashboardWithData.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(loadDashboardYears.fulfilled, (state, action) => {
        state.status = 'idle';
        state.supportTables.years = action.payload;
      })
      .addCase(loadDashboardYears.rejected, (state) => {
        state.status = 'failed';
        console.error('dashboard failed to load. Maybe the business id is invalid, or has been deleted.')
      })
      .addCase(getDiseaseAreaSupportTables.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getDiseaseAreaSupportTables.fulfilled, (state, action) => {
        state.status = "idle";
        state.supportTables.diseaseAreas = action.payload;
        state.diseaseArea = null;
      })  
      .addCase(getDiseaseAreaSupportTables.rejected, (state)=> {
        state.status = "failed";
        console.error('unable to retrieve disease area support tables');
      });
  }
});

//exported actions
export const {
  setBusinessUnitDialogState,
  updateFilter,
  updatePhysicians,
  closePhysicianSnackbar,
  closeExportModal,
  openExportModal,
  openPreviewModal,
  closePreviewModal,
  setInsightSnackbarState,
  clearFilters,
  reset,
  updateStartDate,
  updateStopDate,
  updatePreview
} = dashboardSlice.actions;

export const selectDashboard = (state : RootState) => state.dashboard;

export default dashboardSlice.reducer;