import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { Link } from 'react-router-dom';
import Config from 'utils/config'

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Typography,
  IconButton
} from '@mui/material'

import {
  Delete as DeleteIcon,
  Download as DownloadIcon
} from '@mui/icons-material';

import DeleteDialog from 'components/delete-dialog';
import { getDiseaseAreas, selectBusinessUnit } from 'store/slices/business-unit';
import { selectAdmin } from 'store/slices/admin';
import Loader from '../../../components/loader';
import InsightsClient from '../../../api/insights-client';
import DiseaseAreaClient from '../../../api/disease-area-client';

const ManageBusinessUnit = () => {
 
  const [deleteDiseaseAreaId, setDeleteDiseaseAreaId] = useState<string | null>(null);
  const [insightsClient] = useState(new InsightsClient())
  const [diseaseAreaClient] = useState(new DiseaseAreaClient())

  const dispatch = useAppDispatch();
  const businessUnitStore = useAppSelector(selectBusinessUnit)
  const adminStore = useAppSelector(selectAdmin)

  const handleExport = async (id : string, filename: string)  => {
    await insightsClient.exportDiseaseArea(adminStore.accessToken, { id }, filename)
  }

  const startDeleteDiseaseArea = (id : string) => {
    setDeleteDiseaseAreaId(id);
  }

  const onCloseDeleteDiseaseAreaDialog = () => {
    setDeleteDiseaseAreaId(null);
  }

  const onDeleteDiseaseAreaSubmit = (id: any) => {
    diseaseAreaClient.deleteDiseaseAreaAsync(adminStore.accessToken, id).then(() => {
      setDeleteDiseaseAreaId(null)
      dispatch(getDiseaseAreas(businessUnitStore.selectedBusinessUnitId!))
    })
  }

  useEffect(() => {
    dispatch(getDiseaseAreas(businessUnitStore.selectedBusinessUnitId!));
  }, [dispatch, businessUnitStore.selectedBusinessUnitId])

  return (
    <>
      {businessUnitStore.diseaseAreaStatus === 'loading' 
      ? <Loader></Loader>
      : <>
        <Box sx={{ margin: '16px 0;', flexGrow:1 }}>
          {businessUnitStore.selectedBusinessUnit === null
            ? <Button variant="contained" disabled>Add Disease Area</Button>
            : <Button component={Link} to='/admin/disease-area' variant="contained">Add Disease Area</Button>
          }
        </Box>
        <TableContainer component={Paper}>
          <Table area-label="disease areas">
            <TableHead>
              <TableRow>
                <TableCell>
                  Disease Area
                </TableCell>
                <TableCell>
                  Fiscal Year
                </TableCell>
                <TableCell align='right'>
                  MCPs
                </TableCell>
                <TableCell align='right'>
                  Questions
                </TableCell>
                <TableCell align='right' >
                  Insights
                </TableCell>
                <TableCell align='right'sx={{width: '0%'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                businessUnitStore.diseaseAreas && businessUnitStore.diseaseAreas.length !== 0
                ? businessUnitStore.diseaseAreas.map((value, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link to={`/admin/disease-area/${value.id}`}>{value.name}</Link>
                    </TableCell>
                    <TableCell>
                      {value.year}
                    </TableCell>
                    <TableCell align='right'>{value.beliefCount}/{Config.MAX_BELIEFS}</TableCell>
                    <TableCell align='right'>{value.questionCount}/{Config.MAX_DISEASE_AREAS_QUESTIONS}</TableCell>
                    <TableCell align='right'>{value.insightCount}</TableCell>
                    <TableCell  padding="checkbox" sx={{whiteSpace: 'nowrap'}}>
                      <Tooltip arrow={true} title="Export Data" sx={{mr:1}}>
                        <IconButton aria-label="export" onClick={() => handleExport(value.id, `${value.name.replace(/\./g, '')}-${value.year}`)}  size="small">
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow={true} title="Delete Disease Area"sx={{mr:1}}>
                        <IconButton aria-label="delete" onClick={() => startDeleteDiseaseArea(value.id)}  size="small">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box sx={{textAlign: 'center'}}>
                      <Typography variant="caption" component="em">
                        {businessUnitStore.diseaseAreas && businessUnitStore.diseaseAreas.length === 0 && <span>No Disease Areas to display.</span>}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <DeleteDialog
          open={deleteDiseaseAreaId !== null}
          id={deleteDiseaseAreaId}
          onClose={onCloseDeleteDiseaseAreaDialog}
          onSubmit={onDeleteDiseaseAreaSubmit}
        >
          <span>Are you sure you want to delete? You will not be able to recover this data.</span>
        </DeleteDialog>
      </>}
    </>
  )
}

export default ManageBusinessUnit;