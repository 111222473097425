import { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';

import {
  Box,
  AlertProps,
  Snackbar,
  Alert
} from '@mui/material'

import { selectBusinessUnit } from 'store/slices/business-unit';
import Loader from 'components/loader';
import BusinessUnitsGrid from './grid';
import DeleteBusinessUnitDialog from './delete-dialog';
import AddBusinessUnit from './add-business-unit';

const ManageBusinessUnits = () => {

  const [deleteBusinessUnitId, setDeleteBusinessUnitId] = useState<string | null>(null)
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null)

  const businessUnitStore = useAppSelector(selectBusinessUnit)

  return (
    <>
      {businessUnitStore.diseaseAreaStatus === 'loading' 
      ? <Loader></Loader>
      : <>
        <Box sx={{ margin: '16px 0;', flexGrow:1 }}>
          <AddBusinessUnit setSnackbar={setSnackbar} businessUnits={businessUnitStore.businessUnits!} />
        </Box>
        <Box sx={{ margin: '16px 0;', flexGrow:1 }}>
          <BusinessUnitsGrid setSnackbar={setSnackbar} setDeleteBusinessUnitId={setDeleteBusinessUnitId} />
        </Box>

        <DeleteBusinessUnitDialog
          id={deleteBusinessUnitId}
          onClose={() => setDeleteBusinessUnitId(null)}
          onDeleted={() => setSnackbar({ children: 'Busienss unit successfully deleted', severity: 'success' })}
        />

        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setSnackbar(null)}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )}
        </>}
    </>
  )
}

export default ManageBusinessUnits;