import { useEffect, useState, FormEvent, useCallback } from 'react';
import { DataGrid, GridColumns, GridRowsProp, GridCellEditCommitParams, MuiEvent, GridRowModel } from '@mui/x-data-grid';
import AdminClient from 'api/admin-client';
import SearchBar from 'features/search-bar';
import { UserBusinessUnits } from 'utils/types';
import { useAppSelector } from 'app/hooks';
import { selectBusinessUnit } from 'store/slices/business-unit';
import { selectAdmin } from 'store/slices/admin';
import Loader from 'components/loader';
import {
  Box, Snackbar, AlertProps, Alert
} from '@mui/material'
import { BusinessUnitProps } from 'utils/types';

const ManageUsers = () => {

  const [loading, setLoading] = useState(true)
  const [adminClient] = useState(new AdminClient())
  const [gridData, setGridData] = useState<GridRowsProp>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredGridData, setFilteredGridData] = useState<GridRowsProp>([])
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const businessUnitStore = useAppSelector(selectBusinessUnit)
  const adminStore = useAppSelector(selectAdmin)

  const onSearch = (event : FormEvent<Element>, value: string) => {
    event.preventDefault();
    setSearchTerm(value)
  }

  const convertToGridRow = useCallback((userBusinessUnit : UserBusinessUnits) : any[] => {
    let user: any = {
      id: userBusinessUnit.email,
      email: userBusinessUnit.email
    }

    businessUnitStore.businessUnits!.forEach((businessUnit: BusinessUnitProps) => user[businessUnit.id] = userBusinessUnit.businessUnits.includes(businessUnit.id))

    return user
  }, [businessUnitStore.businessUnits])

  useEffect(() => {
    setFilteredGridData(searchTerm !== '' ? gridData.filter(x => x.email.includes(searchTerm)) : gridData)
  }, [gridData, searchTerm])

  useEffect(() => {
    setLoading(true)
    adminClient.getUserBusinessUnitsAsync(adminStore.accessToken)
      .then((userBusinessUnits: UserBusinessUnits[]) => {
        setGridData(userBusinessUnits.map(convertToGridRow))

        setLoading(false)
    })
  }, [adminClient, businessUnitStore.businessUnits, convertToGridRow, adminStore])

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {

      let user: UserBusinessUnits = {
        email: newRow.email,
        businessUnits: businessUnitStore.businessUnits!.filter((x: BusinessUnitProps) => newRow[x.id] === true).map((x: BusinessUnitProps) => x.id)
      }

      const response = await adminClient.updateUserBusinessUnitAsync(adminStore.accessToken, user)
      setSnackbar({ children: 'User successfully saved', severity: 'success' });
      return convertToGridRow(response);
    },
    [adminClient, businessUnitStore.businessUnits, convertToGridRow, adminStore],
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const gridColumns: GridColumns = [
    { field: 'email', headerName: 'Email', editable: false, flex: 3 },
    ...businessUnitStore.businessUnits!.map(x => {
      return {
        field: x.id, 
        headerName: x.name, 
        type: 'boolean', 
        editable: true,
        flex: 1
      }
    })
  ]

  return (
    <>
    {
      loading ? <Loader></Loader>
      : <>
        <Box sx={{ margin: '16px 0;', flexGrow:1 }}>
          <SearchBar placeholder='Search users' onSubmit={onSearch} />
        </Box>
        <Box sx={{ margin: '16px 0;', flexGrow:1 }}>
          <DataGrid
            autoHeight={true}
            initialState={{
              sorting: {
                sortModel: [{ field: 'email', sort: 'asc' }]
              }
            }}
            pageSize={25}
            rows={filteredGridData}
            columns={gridColumns}
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            onCellEditCommit={(props: GridCellEditCommitParams, event: MuiEvent) => {
              console.log(props)
              console.log(event)
            }}
          />
        </Box>
        {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setSnackbar(null)}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      </>
    }
    </>
  );
}

export default ManageUsers;