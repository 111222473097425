import { useState, FormEvent } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchBarProps {
  placeholder?: string
  onSubmit?: (event: FormEvent<Element>, newValue : string)=>void;
}

const SearchBar = (props : SearchBarProps) => {

  const [value, setValue] = useState('');

  const handleSubmit = (event : FormEvent<Element>) => {
    if(props.onSubmit) props.onSubmit(event, value);
  }

  const handleChange = (event : any) => {
    setValue(event.target.value);
  }

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: "600px" }}
    >
      <InputBase
        type="search"
        fullWidth
        sx={{ ml: 1, flex: 1 }}
        placeholder={props.placeholder}
        inputProps={{ 'aria-label': props.placeholder }}
        onChange={handleChange}
      />

      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>

    </Paper>
  );
}

export default SearchBar;