import { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import {
  AlertProps,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material'

import {
  Add as AddIcon
} from '@mui/icons-material';

import { selectAdmin } from 'store/slices/admin';
import { useValidator, ValidatorMapProps } from 'features/validator';
import BusinessUnitsClient from 'api/business-unit-client';
import { fetchBusinessUnits } from 'store/slices/business-unit';
import { BusinessUnitProps } from 'utils/types'

interface AddBusinessUnitProps {
  setSnackbar: (snackbar: Pick<AlertProps, 'children' | 'severity'>) => void
  businessUnits: BusinessUnitProps[]
}

const AddBusinessUnit = ({ setSnackbar, businessUnits }: AddBusinessUnitProps) => {
 
  const businessUnitValidations : ValidatorMapProps[] = [
    {
      name: 'name',
      required: true,
      errorMessage: 'You must enter a business unit name.',
      validate: (value, validators) => {
        return !validators.isStringNullOrWhitespace(value);
      }
    },
    {
      name: 'name',
      required: true,
      errorMessage: 'Business unit name must be unique.',
      validate: (value) => {
        return !businessUnits.some(x => x.normalizedName.localeCompare(value.toUpperCase()) === 0)
      }
    }
  ];

  const { validate, errors } = useValidator(businessUnitValidations)

  const [newBusinessUnit, setNewBusinessUnit] = useState<any>({ name: '' })
  const [businessUnitsClient] = useState(new BusinessUnitsClient())

  const dispatch = useAppDispatch()
  const adminStore = useAppSelector(selectAdmin)

  const handleChange = (e: any) => {
		setNewBusinessUnit({ ...newBusinessUnit, [e.target.name]: e.target.value })
		validateForm({
			[e.target.name]: e.target.value
		})
	}

  const validateForm = (overrideData: any | undefined): boolean => {
		const data = {
			...newBusinessUnit,
			...overrideData
		}

		return validate(data)
	}

  const submitForm = () => {
		if(validateForm(undefined)) {
			businessUnitsClient.postAsync(adminStore.accessToken, newBusinessUnit)
			.then(() => {
        setSnackbar({ children: 'Business Unit Added', severity: 'success' })
				dispatch(fetchBusinessUnits())
        setNewBusinessUnit({ name: '' })
			})
			.catch((error: any) => {	
				console.error("error: " + error);
        setSnackbar({ children: 'Adding business unit failed', severity: 'error' })
			})
		}
	}

  return (
    <form>
      <Typography variant="h6" mb="8px">Add New Business Unit</Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <TextField
          label="Business Unit Name"
          name="name"
          variant="outlined"
          sx={{ flex: 1 }}
          size="small"
          required
          value={newBusinessUnit.name}
          onChange={handleChange}
          error={errors['name'] ? true : false}
          helperText={errors['name'] ? errors['name'].message : ''}
        />
        <Box>
          <Button onClick={submitForm} variant="contained" startIcon={<AddIcon />}>Add Business Unit</Button>
        </Box>
      </Box>
    </form>
  )
}

export default AddBusinessUnit;