import { useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Toolbar,
	Tooltip,
	Typography
} from '@mui/material';

import {
	Logout as LogoutIcon
} from '@mui/icons-material';

import { 
	setDialogState
} from '../store/slices/insight';
import { reset, selectDashboard, setBusinessUnitDialogState } from '../store/slices/dashboard';
import SelectBusinessUnitDialog from 'features/select-business-unit-dialog';
import { selectBusinessUnit, setBusinessUnit, fetchBusinessUnits } from '../store/slices/business-unit';
import { useOktaAuth } from '@okta/okta-react';
import { setAccessToken, setCurrentUser, selectAdmin } from '../store/slices/admin';
import MobileMenu from './mobile-menu'
import Config from 'utils/config'

const DefaultLayout = (props: any) => {

	const history = useHistory()
	const location = useLocation();
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down('md'));
	const { authState, oktaAuth } = useOktaAuth();
	const [openInsightDialogError, setOpenInsightDialogError] = useState(false);

	const dispatch = useAppDispatch();
	const dashboardStore = useAppSelector(selectDashboard)
	const adminStore = useAppSelector(selectAdmin);
	const businessUnitStore = useAppSelector(selectBusinessUnit)

	const handleInsightDialogError = () => {
		setOpenInsightDialogError(false);
	}

	const handleOpenInsightDialog = () => {
		if(dashboardStore.diseaseArea) {
			dispatch(setDialogState(true))
		} else {
			setOpenInsightDialogError(true);
		}
	}
	// open the business unit dialog
	const handleOpenBusinessUnitDialog = () => {
		dispatch(setBusinessUnitDialogState(true))
	}

	// callback when dialog closed
	const handleBusinessUnitDialogClose = () => {
		dispatch(setBusinessUnitDialogState(false));
	}

	// dialog when dialoged submitted including the data.
	const handleBusinessUnitDialogSubmit = (value : any) => {
		dispatch(reset())
		dispatch(setBusinessUnit(value));
		history.push("/");
		dispatch(setBusinessUnitDialogState(false));
	}

	const logout = async() => {
		await oktaAuth.signOut();
	}

	useEffect(() => { 
		if(authState == null || !authState.isAuthenticated) { 
		  // When user isn't authenticated, forget any user info
		  dispatch(setCurrentUser(null));
		} else { 
			dispatch(setAccessToken(authState.accessToken!.accessToken));
			dispatch(setCurrentUser({
				name: authState.accessToken!.claims.sub,
				username: authState.accessToken!.claims.sub,
				id: authState.accessToken!.claims.sub,
				email: authState.accessToken!.claims.email ?? "",
				isAdmin: (authState.accessToken!.claims.Groups as string[] | undefined)?.includes(Config.ADMIN_GROUP) ?? false
			}));
			dispatch(fetchBusinessUnits());
		}
	  }, [dispatch, authState, oktaAuth]); // Update if authState changes

	return (
 		<>
		 	<AppBar
				position="relative"
				elevation={6}
			>
				<Toolbar>
					<Box component="img" src="/static/images/takeda_logo.svg" sx={{width: "6rem", mr: 2}}  />
					{adminStore.currentUser !== null &&
					<>
						<Typography variant="h6" component="div" sx={{ textTransform: "uppercase" }}>
							{businessUnitStore.selectedBusinessUnit?.name} Dashboard
						</Typography>
						{ !mobileView ?
							<>
								{adminStore.currentUser.isAdmin && <>
									<Button 
										component={Link} 
										variant={!location.pathname.startsWith('/admin') ? 'outlined' : 'text'} 
										to="/" 
										color="inherit" 
										sx={{mr:2, ml: 2}}>Home</Button>
									<Button 
										component={Link} 
										variant={location.pathname.startsWith('/admin') ? 'outlined' : 'text'} 
										to="/admin" 
										color="inherit" 
										sx={{mr:1}}>Admin</Button>
								</>}
								<Box sx={{flexGrow:1}}></Box>

								{businessUnitStore.businessUnits && businessUnitStore.businessUnits.length > 1 && !location.pathname.startsWith('/admin/disease-area') &&
									<Tooltip title="Change business unit." arrow={true} dir="up">
										<Button color="inherit" aria-label='Refresh' sx={{mr:1}} onClick={handleOpenBusinessUnitDialog}>Change Business Unit</Button>
									</Tooltip>
								}

								{businessUnitStore.selectedBusinessUnit && !location.pathname.startsWith('/admin') &&
									<Button color="inherit" aria-label='Add new insight' sx={{mr:1}} onClick={handleOpenInsightDialog}>Add Insight</Button>
								}

								<Tooltip title="Logout" arrow={true} dir="up">
									<IconButton color="inherit" aria-label="Logout" onClick={logout}>
										<LogoutIcon />
									</IconButton>
								</Tooltip>
							</>
							:
							<>
								<Box sx={{flexGrow:1}}></Box>
								<MobileMenu handleOpenInsightDialog={handleOpenInsightDialog} handleOpenBusinessUnitDialog={handleOpenBusinessUnitDialog} />
							</>
						}
					</>
					}
				</Toolbar>
			</AppBar>

			<Box component="main" sx={{flexGrow: 1, padding: 2}}>
				{props.children}
			</Box>

			<Dialog
				open={openInsightDialogError}
				onClose={handleInsightDialogError}
				aria-labelledby="insight-dialog-error-title"
				aria-describedby="insight-dialog-error-description"
			>
				<DialogTitle id="insight-dialog-error-title">
					Unable to add insights
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="insight-dialog-error-description">
						You must select a year and a disease area before you can add insights.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleInsightDialogError}>Close</Button>
				</DialogActions>
      		</Dialog>

			<SelectBusinessUnitDialog 
				open={dashboardStore.businessUnitDialogState}
				onClose={handleBusinessUnitDialogClose}
				onSubmit={handleBusinessUnitDialogSubmit}
			/>
		</>
	)
}

export default DefaultLayout;