export async function fetchWithAuth(accessToken: string, url: string, body: RequestInit | undefined): Promise<Response> {

  let newBody = {
    ...body,
    headers: {
      ...body?.headers ?? '',
      Authorization: 'Bearer ' + accessToken
    }
  }

  return await fetch(url, newBody)
}