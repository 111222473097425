import { useState, createRef, ChangeEvent } from 'react';
import { StringHelper } from 'utils';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button
} from '@mui/material';

export interface BeliefDialogProps {
  open: boolean;
  defaultValue: string;
  editId: number;
  onClose?: () => void;
  onSubmit?: (formData : any, editId : number | null) => void;
}

const BeliefDialog = (props : BeliefDialogProps) => {

  const [value, setValue] = useState<string>(props.defaultValue)
  const [error, setError] = useState<boolean>(false);

  const inputRef = createRef<any>();

  const handleClose = () => {
    if(props.onClose) props.onClose();
  }
  
  const handleSubmit = () => {
    if(!StringHelper.isNullOrEmpty(value)) {
      
      if(props.onSubmit) props.onSubmit(value, props.editId);
      setValue('');
    } else {
      // there is an error.
      setError(true);
      inputRef.current.focus()
    }
  }

  const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Enter a new MCP</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{mb:2}}>
              To create a new MCP, please fill out the form below. You can modify the MCP after it is submitted.
            </DialogContentText>
            
            <TextField
              inputRef={inputRef}
              autoFocus
              margin="dense"
              id="belief-input"
              label="Enter an MCP"
              type="email"
              minRows={3}
              maxRows={6}
              fullWidth
              multiline
              required
              error={error}
              helperText={error ? "You must enter an MCP before submitting." : ""}
              defaultValue={props.defaultValue}
              onChange={handleChange}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">Submit</Button>
          </DialogActions>
        </Dialog>
  )
}

export default BeliefDialog;