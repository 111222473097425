import * as React from 'react';
import { useAppSelector } from "app/hooks";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material'
import { selectBusinessUnit } from 'store/slices/business-unit';


export interface SelectBusinessUnitProps {
	open: boolean;
	onSubmit: (value : any) => void;
	onClose: () => void;
}

const SelectBusinessUnitDialog : React.FunctionComponent<SelectBusinessUnitProps> = (props) => {

  const businessUnitStore = useAppSelector(selectBusinessUnit);

  const [businessUnitId, setBusinessUnitId] = React.useState<string | null>(businessUnitStore.selectedBusinessUnitId);

  const handleClose = () => {
    if(props.onClose) props.onClose();
  }

  const handleSubmit= () => {
    if(props.onSubmit) props.onSubmit(businessUnitId);
  }

  const handleChange = (e : SelectChangeEvent) => {
    setBusinessUnitId(e.target.value)
  }

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle id="delete-dialog-title">Business Units.</DialogTitle>
      <DialogContent>
				<DialogContentText sx={{mb:2}}>
					Select one of the following business units:
				</DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="business-unit-select-label">Business Unit</InputLabel>
          <Select
            labelId="business-unit-select-label"
            id="business-unit-select"
            value={businessUnitId ?? ''}
            label="Business Unit"
            onChange={handleChange}
          >
            {
              businessUnitStore &&
              businessUnitStore.businessUnits?.map((value, index) => (
                <MenuItem value={value.id} key={`business-unit-key-${index}`}>{value.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
			</DialogContent>
			<DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus variant="contained">Submit</Button>
        </DialogActions>
    </Dialog>
  )
}

export default SelectBusinessUnitDialog;