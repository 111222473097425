import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';

import DiseaseAreaClient from 'api/disease-area-client';
import { selectAdmin } from 'store/slices/admin'
import { selectBusinessUnit } from 'store/slices/business-unit'
import { useAppSelector } from 'app/hooks';

import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Grid,
	TextField,
	Typography
} from '@mui/material';

import {
	LocalizationProvider,
	DatePicker
} from '@mui/lab'

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import SuccessDialog from './success-dialog'
import { useValidator, ValidatorMapProps } from 'features/validator';
import DiseaseAreaBeliefs from './beliefs/beliefs';

import {
	BeliefProps,
	QuestionProps
} from '../../../utils/types';

import Loader from '../../../components/loader';
import DiseaseAreaQuestions from './questions/questions';

export interface DiseaseAreaFormData {
	id: string | null;
	name: string;
	date: Date | null;
	year : number | undefined;
}

export interface DeleteBeliefProps {
	id: any,
	open: boolean;
}

// date input dates...
const today = new Date()
const minDate = new Date('2021-03-01');
const maxDate = new Date(today.getFullYear() + 10, 1 ,1);

const validations : ValidatorMapProps[] = [
	{
		name: 'name',
		required: true,
		errorMessage: 'You must enter a disease area name.',
		validate: (value, validators) => {
			return !validators.isStringNullOrWhitespace(value);
		}
	},
	{
		name: 'date',
		required: true,
		errorMessage: 'Please select a year for this disease area.',
		validate: (value, validators) => {
			return validators.isDate(value);
		}
	},
	{
		name: 'beliefs',
		required: true,
		errorMessage: 'You must have at least one MCP per disease area.',
		validate: (value : any, validators) => {
			if(!Array.isArray(value)) return false;
			if(value.length >= 1) return true;
			
			return false;
		}
	}
]

const DiseaseArea = () => {
	const { guid } = useParams<any>();
	const { validate, errors } = useValidator(validations);
	const adminStore = useAppSelector(selectAdmin);
	const businessUnitStore = useAppSelector(selectBusinessUnit);

	const [formData, setFormData] = useState<DiseaseAreaFormData>({
		id: null,
		name: '',
		date: new Date(),
		year: (new Date()).getFullYear()
	} as DiseaseAreaFormData);
	
	const [loading, setLoading] = useState(!!guid);
	const [beliefs, setBeliefs] = useState<BeliefProps[]>([]);
	const [questions, setQuestions] = useState<QuestionProps[]>([]);
		
	const [success, setSuccess] = useState(false);
	const [diseaseAreaClient] = useState(new DiseaseAreaClient())

	const editMode = !!guid;

	const getDiseaseAreaData = useCallback((guid : string | undefined) => {
		if(!guid || !adminStore.accessToken) {
			return;
		}

		setLoading(true)
		diseaseAreaClient.getDiseaseAreaAsync(adminStore.accessToken, guid!)
			.then((result: any) => {
				setBeliefs(result.beliefs);
				setQuestions(result.questions);
				setFormData({
					id: result.id,
					name: result.name,
					date: new Date(`"${result.year}-1-1"`),
					year: result.year
				});
				
				setLoading(false);
			})
	}, [adminStore.accessToken, diseaseAreaClient])

	useEffect(() => {
		getDiseaseAreaData(guid)
	}, [guid, getDiseaseAreaData])

	const handleChange = (e: any) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		validateForm({
			[e.target.name]: e.target.value
		})
	}

	const validateForm = (overrideData: any | undefined): boolean => {
		const data = {
			...formData,
			businessUnitId: businessUnitStore.selectedBusinessUnitId!,
			beliefs: beliefs,
			questions: questions,
			...overrideData
		}

		return validate(data)
	}

	// submits the form. If successfull, a dialog will be displayed with only the option to continue.
	const submitForm = () => {
		if(validateForm(undefined)) {
			diseaseAreaClient.postDiseaseAreaAsync(adminStore.accessToken, {
				...formData,
				businessUnitId: businessUnitStore.selectedBusinessUnitId!,
				beliefs: beliefs,
				questions: questions
			})
			.then(() => {
				setSuccess(true);
			})
			.catch((error: any) => {	
				console.error("error: " + error);
			})
		}
	}
	
	return (
		<>
			<SuccessDialog
				open={success}
				guid={guid}
			/>
			{loading
				? <Loader />
				:
				<form>

					<Container>
						<Card sx={{ mb: 4 }}>
							<CardContent>
								<Box sx={{ mb: 2 }}>
									<Typography variant="h4">
										<strong>{editMode ? 'Modify Disease Area' : 'Create a New Disease Area'}</strong>
									</Typography>
									<Typography variant="subtitle1" component="p">
										{editMode ? 'Complete the form below to modify this Disease Area.' : 'Complete the form below to create a new Disease Area.'}
									</Typography>
									<hr />

									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<TextField
												label="Disease area name"
												name="name"
												variant="outlined"
												sx={{ width: '100%' }}
												defaultValue={formData.name}
												required
												onChange={handleChange}
												error={errors['name'] ? true : false}
												helperText={errors['name'] ? errors['name'].message : ''}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													views={['year']}
													label="Fiscal Year"
													onChange={(newValue)=>{
														const temp = {...formData};
														temp.date = newValue;
														temp.year = newValue?.getFullYear()
														setFormData(temp);
													}}
													value={ formData.date }
													minDate={minDate}
													maxDate={maxDate}
					
													renderInput={(params) => <TextField {...params} helperText={null} fullWidth name="year" required />}
												/>
											</LocalizationProvider>
										</Grid>
									</Grid>
								</Box>

								<DiseaseAreaBeliefs beliefs={beliefs} setBeliefs={setBeliefs} errors={errors} validateForm={validateForm}></DiseaseAreaBeliefs>

								<DiseaseAreaQuestions questions={questions} setQuestions={setQuestions} editMode={editMode} ></DiseaseAreaQuestions>

								<hr/>
								<Button onClick={submitForm} variant="contained" size="large">{editMode ? 'Update' : 'Submit'}</Button>
								{editMode && <Button variant="text" size="large" sx={{ml: 2}} color="neutral" component={Link} to="/admin">Cancel</Button>}
							</CardContent>
						</Card>
			
					</Container>
				</form>
			}
		</>
	);
}

export default DiseaseArea;