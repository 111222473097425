import { UserBusinessUnits } from "../utils/types";
import { fetchWithAuth } from "./request";

export default class AdminClient {

    public async getUserBusinessUnitsAsync(accessToken: string): Promise<UserBusinessUnits[]> {
        return await fetchWithAuth(accessToken, '/api/admin/user-business-units', {
            method: 'GET'
        })
        .then(result => result.json());
    }

    public async updateUserBusinessUnitAsync(accessToken: string, user: UserBusinessUnits): Promise<UserBusinessUnits> {
        return await fetchWithAuth(accessToken, '/api/admin/user-business-units', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        })
        .then(result => result.json());
    }
}