import { useState } from 'react';
import Config from 'utils/config'

import {
	Box,
	Button,
	IconButton,
	Paper,
	Table,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
	Tooltip,
	Typography
} from '@mui/material';

import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Edit as EditIcon
} from '@mui/icons-material';

import DeleteDialog from 'components/delete-dialog';
import { QuestionProps } from 'utils/types';
import QuestionDialog from './question-dialog';

export interface DiseaseAreaBeliefsProps {
	questions: QuestionProps[],
	setQuestions: (beliefs: QuestionProps[]) => void,
	editMode: boolean
}

export interface DeleteBeliefProps {
	id: any,
	open: boolean;
}

const DiseaseAreaQuestions = ({ editMode, questions, setQuestions }: DiseaseAreaBeliefsProps) => {
	
	const [deleteQuestionDialog, setDeleteQuestionDialog] = useState<number | null>(null);
	const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
	const [questionValue, setQuestionValue] = useState<QuestionProps | undefined>();
	const [editQuestionIndex, setQuestionIndex] = useState(-1)

	const handleQuestionDialogClose = () => {
		setOpenQuestionDialog(false)
		setQuestionValue(undefined)
		setQuestionIndex(-1)
	}

	const editQuestion = (value: any, index : number) => {
		setQuestionIndex(index)
		setQuestionValue(value)
		setOpenQuestionDialog(true)
	}

	const handleQuestionDialogSubmit = (value : any, index: number) => {
		value.options = value.options.filter((x: any) => x.value.length > 0)
		const temp = [...questions];

		if(index !== undefined && index !== -1) {
			temp[index] = value;
		} else {
			temp.push(value)
		}

		setQuestions(temp)
		setOpenQuestionDialog(false)
		setQuestionIndex(-1)
		setQuestionValue(undefined)
	}

	const deleteQuestion = (value : number) => {
		setDeleteQuestionDialog(value);
	}
	const onSubmitDeleteQuestionDialog = (value : number) => {
		
		const temp = [...questions];
		temp.splice(value, 1);
		setQuestions(temp);

		setDeleteQuestionDialog(null);
	}

	const onCloseDeleteQuestionDialog = () => {
		setDeleteQuestionDialog(null);
	}
	
	return (
		<>
			<Box sx={{ mb: 2 }}>
			{questions.length < Config.MAX_DISEASE_AREAS_QUESTIONS
				? <Button
					variant="contained"
					startIcon={<AddIcon />}
					onClick={() => setOpenQuestionDialog(true)}
					sx={{ mr: 2 }}
				>Add Question</Button>
				: <Button variant="contained" disabled>Add Question</Button>
			}
			</Box>

			<TableContainer component={Paper} sx={{ mb: 4 }}>
				<Table sx={{ minWidth: 650 }} aria-label="Beliefs">
					<caption>you have <strong>{Config.MAX_DISEASE_AREAS_QUESTIONS - questions.length}/{Config.MAX_DISEASE_AREAS_QUESTIONS}</strong> questions remaining.</caption>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '0%' }}>#</TableCell>
							<TableCell>Question</TableCell>
							<TableCell align='right'>Options</TableCell>
							<TableCell sx={{ width: '0%' }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>

						{questions.map((question, index) => (
							<TableRow key={index}>
								<TableCell>
									{index + 1}
								</TableCell>
								<TableCell>
									{question.question}
								</TableCell>
								<TableCell align='right'>
									{question.options.length - 1}
								</TableCell>
								<TableCell padding="checkbox" sx={{ whiteSpace: 'nowrap', pr: 1 }} >
									<Tooltip arrow={true} title="Edit Question">
										<IconButton aria-label="edit" onClick={() => editQuestion(question, index)} size="small">
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip arrow={true} title="Delete Belief">
										<IconButton aria-label="delete" onClick={() => deleteQuestion(index)}  size="small">
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<DeleteDialog
				open={deleteQuestionDialog !== null}
				id={deleteQuestionDialog}
				onSubmit={onSubmitDeleteQuestionDialog}
				onClose={onCloseDeleteQuestionDialog}
			>
				<Typography sx={{mb:2}}>Your belief and any related data will be permanently deleted.</Typography>
				<Typography variant="caption">
					<em>Changes will not take effect until the form is submitted.</em>
				</Typography>
			</DeleteDialog>

			{openQuestionDialog && <QuestionDialog
				open={openQuestionDialog}
				editMode={editMode}
				editId={editQuestionIndex}
				question={questionValue}
				onSubmit={handleQuestionDialogSubmit}
				onClose={handleQuestionDialogClose}
			/>}
		</>
	);
}

export default DiseaseAreaQuestions;