import * as React from 'react';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import IconAdd from '@mui/icons-material/Add';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { useAppSelector } from '../app/hooks';
import { selectAdmin } from '../store/slices/admin';
import { selectBusinessUnit } from '../store/slices/business-unit';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';

interface MobileMenuInterface {
	handleOpenInsightDialog: () => void,
	handleOpenBusinessUnitDialog: () => void
}

const MobileMenu = ({ handleOpenInsightDialog, handleOpenBusinessUnitDialog } : MobileMenuInterface) => {

	const location = useLocation();
	const adminStore = useAppSelector(selectAdmin)
	const businessUnitStore = useAppSelector(selectBusinessUnit)
	const { oktaAuth } = useOktaAuth()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const logout = async() => {
		await oktaAuth.signOut();
	}

	const mobileHandleOpenInsightDialog = () => {
		handleOpenInsightDialog()
		handleClose()
	}
	// open the business unit dialog
	const mobileHandleOpenBusinessUnitDialog = () => {
		handleOpenBusinessUnitDialog()
		handleClose()
	}

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<IconButton
					onClick={handleClick}
					size="large"
					color="inherit"
					aria-controls={open ? 'mobile-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				>
					<MoreVertIcon />
				</IconButton>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="mobile-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{adminStore.currentUser!.isAdmin && <>
					<MenuItem selected={!location.pathname.startsWith('/admin')}>
						<Button component={Link} variant='text' to="/" color="inherit" sx={{p:0, flex: 1, justifyContent:'flex-start'}}>Home</Button>
					</MenuItem>
					<MenuItem selected={location.pathname.startsWith('/admin')}>
						<Button component={Link} variant='text' to="/admin" color="inherit" sx={{p:0, flex: 1, justifyContent:'flex-start'}}>Admin</Button>
					</MenuItem>
					<Divider />
				</>}
				
				{businessUnitStore.businessUnits && businessUnitStore.businessUnits.length > 1 && !location.pathname.startsWith('/admin/disease-area') &&
					<MenuItem onClick={mobileHandleOpenBusinessUnitDialog}>
						<ListItemIcon>
							<Settings fontSize="small" />
						</ListItemIcon>
						Change Business Unit
					</MenuItem>
				}

				{businessUnitStore.selectedBusinessUnit && !location.pathname.startsWith('/admin') &&
					<MenuItem onClick={mobileHandleOpenInsightDialog}>
						<ListItemIcon>
							<IconAdd fontSize="small" />
						</ListItemIcon>
						Add Insight
					</MenuItem>
				}

				<MenuItem onClick={logout}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
}

export default MobileMenu;