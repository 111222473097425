import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

import {
	User
} from '../../utils/types';

export interface BusinessUnitProps {
	id: string;
  name: string;
  normalizedName: string
}

export type BusinessUnitType = BusinessUnitProps;

export interface AdminState {
	accessToken: string;
	year: string;
	tabIdx: string;
	status: 'idle' | 'loading' | 'failed';
	currentUser: User | null
}

const initialState : AdminState = {
	accessToken: '',
	tabIdx: '',
	year: '',
	status: 'idle',
	currentUser: null
}

export const adminSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		setAccessToken: (state, action : PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
		setCurrentUser: (state, action : PayloadAction<User | null>) => {
			state.currentUser = action.payload;
		}
	}
});

export const {
		setAccessToken,
		setCurrentUser
	} = adminSlice.actions;

export const selectAdmin = (state : RootState) => state.admin;


export default adminSlice.reducer;