import { useState, useCallback, SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import {
  AlertProps,
  Typography
} from '@mui/material'

import { 
  DataGrid, 
  GridColumns, 
  GridRowId,
  MuiEvent,
  GridRowModel,
  GridRowModes,
  GridRowParams,
  GridActionsCellItem,
  GridRowModesModel,
  GridEventListener
} from '@mui/x-data-grid';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

import { selectBusinessUnit, fetchBusinessUnits } from 'store/slices/business-unit';
import { selectAdmin } from 'store/slices/admin';
import BusinessUnitsClient from 'api/business-unit-client';

interface BusinessUnitsGridProps {
  setSnackbar: (snackbar: Pick<AlertProps, 'children' | 'severity'>) => void
  setDeleteBusinessUnitId: (id: string | null) => void
}

const BusinessUnitsGrid = ({ setSnackbar, setDeleteBusinessUnitId }: BusinessUnitsGridProps) => {

  const [businessUnitsClient] = useState(new BusinessUnitsClient())
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const dispatch = useAppDispatch()
  const businessUnitStore = useAppSelector(selectBusinessUnit)
  const adminStore = useAppSelector(selectAdmin)

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteBusinessUnitId(id as string);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {

      let businessUnit = {
        id: newRow.id,
        name: newRow.name
      }

      const response = await businessUnitsClient.putAsync(adminStore.accessToken, businessUnit)
      setSnackbar({ children: 'Busienss unit successfully saved', severity: 'success' });
      dispatch(fetchBusinessUnits())
      return response;
    },
    [businessUnitsClient, adminStore, setSnackbar, dispatch]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, [setSnackbar]);

  const gridColumns: GridColumns = [
    { field: 'id', headerName: 'ID', editable: false, flex: 1 },
    { field: 'name', headerName: 'Name', editable: true, flex: 2 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="secondary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="secondary"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="secondary"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="secondary"
          />,
        ];
      }
    }
  ]

  return (
    <>
      <Typography variant="h6" mb="8px">Manage Existing Business Units</Typography>
      <DataGrid
        autoHeight={true}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          }
        }}
        pageSize={25}
        rows={businessUnitStore.businessUnits!}
        columns={gridColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </>
  )
}

export default BusinessUnitsGrid;