import { useState, useMemo, createContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeOptions, getAppTheme } from '.';
import Config from '../utils/config'

export const AppThemeContext = createContext(
	{
		currentTheme: 'light' as ThemeOptions,
		setTheme: (name : ThemeOptions) => { }
	}
);

// localStorage key for the current set theme.
const themeStorageKey = Config.THEME_STORAGE_KEY;

const AppThemeProvider = (props: any) => {

	const currentTheme = localStorage.getItem(themeStorageKey) as ThemeOptions;
  const [themeName, _setThemeName] = useState<ThemeOptions>(currentTheme ? currentTheme : 'light');

	const theme = useMemo(
    () => getAppTheme(themeName), 
    [themeName],
  );

	const setThemeName = (name : ThemeOptions) => {
    localStorage.setItem(themeStorageKey, name);
    _setThemeName(name);
  }

	const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName
  }

	return (
		<AppThemeContext.Provider value={contextValue}>
			<ThemeProvider theme={theme}>
				{props.children}
			</ThemeProvider>
		</AppThemeContext.Provider>
	)
}

export default AppThemeProvider;